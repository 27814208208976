<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">
                                Members List
                            </h3>

                            <div class="card-tools">                                
                                <form class="form-inline btn btn-sm">
                                    <select id="pagination" v-model="form3.items_per_page" @change="getResults">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                    </select>
                                </form>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="
table table table-hover table-bordered table-striped
">
                                <thead>
                                    <tr>
                                        <th v-if="!$gate.isSuperAdmin()" style="width: 15%">Name</th>
                                        <th v-if="$gate.isSuperAdmin()" style="width: 10%">Name</th>
                                        <th style="width: 10%">Member No.</th>
                                        <th style="width: 10%">ID No.</th>
                                        <th style="width: 10%">Functions</th>
                                        <th style="width: 10%">Phone</th>
                                        <th style="width: 10%">E-mail</th>
                                        <th style="width: 10%">County</th>
                                        <th style="width: 10%">Ownership</th>
                                        <th style="width: 10%">Mortgage</th>
                                        <th v-if="$gate.isSuperAdmin()" style="width: 5%">Expiry</th>
                                        <th style="width: 5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input v-model="form2.name" type="text" class="form-control form-control-sm" placeholder="Name Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.member_no" type="text" class="form-control form-control-sm" placeholder="Member No Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.id_no" type="text" class="form-control form-control-sm" placeholder="ID No. Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.functions" type="text" class="form-control form-control-sm" placeholder="Functions Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.phone" type="text" class="form-control form-control-sm" placeholder="Phone Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.email" type="text" class="form-control form-control-sm" placeholder="Email Search"/>
                                        </td>                                        
                                        <td>
                                            <select v-model="form2.county" class="form-control form-control-sm">
                                                <option value="0" disabled>Select County</option>
                                                <option v-for="(name, index) in counties" :key="index" :value="index" :selected="index == form2.county">
                                                    {{ name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select v-model="form2.ownership" class="form-control form-control-sm">
                                                <option value="">Owner of Residence?</option>
                                                <option v-for="ownership in yes_nos" :value="ownership" :key="ownership">{{ownership}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select v-model="form2.mortgage" class="form-control form-control-sm">
                                                <option value="">Mortgaged Residence?</option>
                                                <option v-for="ownership in yes_nos" :value="ownership" :key="ownership">{{ownership}}</option>
                                            </select>
                                        </td>
                                        <td v-if="$gate.isSuperAdmin()">
                                            <input v-model="form2.member_until" type="text" class="form-control form-control-sm" placeholder="Member Until"/>
                                        </td>    
                                            <td>
                                                <a
                                                    href="#"
                                                    @click="getResults"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="member in members.data"
                                        :key="member.id"
                                    >
                                        <td>{{ member.name }}</td>
                                        <td>{{ member.member_no }}</td>
                                        <td>{{ member.id_no }}</td>
                                        <td>{{ member.functions }}</td>
                                        <td>{{ member.phone }}</td>
                                        <td>{{ member.email }}</td>
                                        <td>
                                            {{
                                                member.county_name
                                            }}
                                        </td>
                                        <td>{{ member.ownership }}</td>
                                        <td>{{ member.mortgage }}</td>
                                        <td>{{ member.meber_until }}</td>
                                        <td v-if="$route.path == '/members'">
                                            <router-link :to="'/edit_member/'+member.id"><i class="fa fa-edit blue"></i></router-link>
                                            /
                                            <a
                                                href="#"
                                                @click="
                                                    deleteMember(
                                                        member.id
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="members"
                                @pagination-change-page="getResults"
                            ></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
// Import datepicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
//bar code modal
import VueBarcode from 'vue-barcode';
export default {
    data() {
        return {
            editmode: false,
            members: {},
            form2: new Form({
                name: "",
                member_no: "",
                id_no: "",
                id_no: "",
                functions: "",
                phone: "",
                email: "",
                county: "",
                ownership:"",
                mortgage: "",
            }),
            form3: new Form({
                items_per_page: "10",
            }),
            week_days : ["Monday","Tuesday","Wednesday","Friday","Thursday","Friday","Saturday","Sunday"],
            counties: [],
            yes_nos: [],            
            users:[],

        };
    },
    components: {
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            var search_string = "";
            if(this.form2.name != ""){
                search_string = search_string + "name=" + this.form2.name + "&";
            }
            if(this.form2.member_no != ""){
                search_string = search_string + "member_no=" + this.form2.member_no + "&";
            }
            if(this.form2.id_no != ""){
                search_string = search_string + "id_no=" + this.form2.id_no + "&";
            }
            if(this.form2.functions != ""){
                search_string = search_string + "functions=" + this.form2.functions + "&";
            }            
            if(this.form2.phone != ""){
                search_string = search_string + "phone=" + this.form2.phone + "&";
            }
            if(this.form2.email != ""){
                search_string = search_string + "email=" + this.form2.email + "&";
            }
            if(this.form2.county != ""){
                search_string = search_string + "county=" + this.form2.county + "&";
            }
            if(this.form2.ownership != ""){
                search_string = search_string + "ownership=" + this.form2.ownership + "&";
            }
            if(this.form2.mortgage != ""){
                search_string = search_string + "mortgage=" + this.form2.mortgage + "&";
            }
            search_string = Buffer.from(search_string).toString('base64');
            axios
                .get("api/member?page=" + page + "&items_per_page=" + this.form3.items_per_page +"&search_string=" + search_string)
                .then(({ data }) => (this.members = data.data));

            this.$Progress.finish();
        },
        cancelSearch(){
            this.form2.name = "";
            this.form2.member_no = "";
            this.form2.id_no = "";
            this.form2.functions = "";
            this.form2.phone = "";
            this.form2.email = "";
            this.form2.county = "";
            this.form2.ownership = "";
            this.form2.mortgage = "";
            this.getResults();
        },

        newModal() {
            this.editmode = false;
            this.promotemode = false;
            this.form.reset();
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        editModal(user){
            this.editmode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(user);
        },
        deleteMember(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    axios
                        .delete("api/member/" + id)
                        .then(() => {
                            Swal.fire(
                                "Deleted!",
                                "Your file has been deleted.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.loadCustomers();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        loadMembers() {
            this.$Progress.start();

            if (this.$gate.isSuperAdmin()) {
                axios
                    .get("api/member")
                    .then(({ data }) => (this.customers = data.data));
            }

            this.$Progress.finish();
        },
        loadCounties() {
            axios
                .get("/api/category/counties")
                .then(({ data }) => (this.counties = data.data));
        },
        loadUsers() {
            axios
                .get("/api/user/list")
                .then(({ data }) => (this.users = data.data));
        },
    },

    mounted() {
        console.log("Member Component mounted.");
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.loadCounties();
        this.$Progress.finish();
    },
};
</script>
