<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Item Sales</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent=""  class="form-horizontal mt-2 ml-4 mr-4">
                  <div class="form-group">
                      <input v-model="form.barcode_id" type="text" name="barcode_id" ref="barcode_id" placeholder="Scan Barcode or type Product Name"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('barcode_id') }" v-on:keyup.enter="search_product" autofocus>
                      <has-error :form="form" field="barcode_id"></has-error>
                  </div>
                </form>
                <div class="container" v-if="products.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in products" :key="product.id">
                                <td>{{product.name}}</td>
                                <td v-on:keyup.enter="changeQuantity(product.id,this.val())">{{product.quantity}}</td>
                                <td>{{product.price}}</td>
                                <td>{{product.total_price}}</td>
                            </tr>
                            <tr v-if="grand_total > 0">
                                <td colspan="3"><b>Total</b></td>
                                <td><b>{{Number(grand_total).toLocaleString()}}</b></td>
                            </tr>
                            <tr v-if="grand_total > 0">
                                <td colspan="4" class="text-sm-left"><button class="btn btn-success" value="Check Out" @click="showCheckOutModal">Check Out</button></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row mb-4">
                        <div class="col-sm-6" style="text-align:center">
                            <img style="width:200px; height:auto;border-radius: 50%;" :src="customer.photo_url"> 
                            <h3>{{customer.name}}</h3>
                            <p>{{customer.f_n_l}}</p>                             
                        </div>
                        <div class="col-sm-6">
                            <h2 :class="customer.text_class">{{customer.authorization}}</h2>
                        </div>
                        
                    </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Check Out Modal -->
        <div
                class="modal fade"
                id="checkOutModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="checkOutModal"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Check Out
                            </h5>
                        </div>
                        <div class="modal-body"></div>
                            <form @submit.prevent=""  class="form-horizontal mt-2 ml-4 mr-4">
                                <input type="hidden" name="grand_total" v-model="form2.grand_total"/>
                                <div class="form-group">
                                    <input v-model="form2.customer_barcode_id" type="text" name="customer_barcode_id" ref="customer_barcode_id" placeholder="Scan ID"
                                        class="form-control" :class="{ 'is-invalid': form2.errors.has('customer_barcode_id') }" v-on:keyup.enter="search_customer" autofocus>
                                    <has-error :form="form" field="customer_barcode_id"></has-error>
                                </div>
                            </form>
                            <div class="container" v-if="customer">
                                <div class="row mb-4">
                                    <div class="col-sm-6" style="text-align:center">
                                        <img  style="width:200px; height:auto;border-radius: 50%;" :src="customer.photo_url"> 
                                        <h3>{{customer.name}}</h3>
                                        <p>{{customer.f_n_l}}</p>                             
                                    </div>
                                    <div class="col-sm-6">
                                        <h2 :class="customer.text_class">{{customer.authorization}}</h2>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button v-if="customer.tuck_shop_balance >= grand_total"
                                    @click="deductFunds"
                                    class="btn btn-success"
                                >
                                    Deduct Funds
                                </button>                                
                            </div>
                    </div>
                </div>
            </div>
    </div>
  </section>
</template>

<script>
import VueMoment from 'vue-moment';
    export default {
        data () {
            return {
                editmode: false,
                product : {},
                products: [],
                customer: {},
                form: new Form({
                    barcode_id : '',
                }),
                form2: new Form({
                    customer_barcode_id : '',
                    grand_total: 0,
                    customer_id: '',
                }),
                grand_total:0,
            }
        },
        components: {
            moment: VueMoment
        },
        methods: {          
          search_product(){
            this.$Progress.start();
            this.form.post('api/product/search_by_barcode')
            .then((response) => {
                this.$Progress.finish();
                var product = response.data.data
                const indexOfObject = this.products.findIndex(object => {
                    return object.id == product.id;
                });
                if(indexOfObject > -1){
                    var old_product = this.products[indexOfObject]
                    var product_to_add = {"name":product.name,"quantity":old_product.quantity+1,"price":old_product.price,"total_price":(old_product.quantity+1)*(old_product.price)}
                    this.products.splice(indexOfObject, 1);
                }
                else{
                    var product_to_add = {"name":product.name,"quantity":1,"price":product.price,"total_price":product.price}
                } 
                this.products.push(product_to_add)
                this.recalculate_total();
                this.form.barcode_id = "";
                this.$refs.barcode_id.focus();
            })
            .catch((error) => {
                this.$Progress.fail();  
                console.log(error)
                this.form.errors.set(error.response.data.errors); 
                this.$Progress.finish();
            });
          },
          search_customer(){
            this.$Progress.start();
            this.form2.customer_id = this.customer.id;            
            this.form2.grand_total = this.grand_total;  
            this.form2.post('api/customer/check_out_by_barcode')
            .then((response) => {
                // success
                this.customer = response.data.data     
                this.$Progress.finish();
                this.form2.customer_barcode_id = "";
                this.$refs.customer_barcode_id.focus();
            })
            .catch(() => {
                this.$Progress.fail();
            });
          },
          deductFunds(){
            this.$Progress.start();
            this.form2.customer_id = this.customer.id;            
            this.form2.grand_total = this.grand_total;  
            this.form2.post('api/customer/deduct_funds')
            .then((response) => {
                // success
                this.customer = response.data.data     
                this.$Progress.finish();
                this.form2.customer_barcode_id = "";
                this.$refs.customer_barcode_id.focus();
                $("#checkOutModal").modal("hide");
                this.products = [];
                Toast.fire({
                    icon: "success",
                    title: response.data.message,
                });
            })
            .catch(() => {
                this.$Progress.fail();
            });
          },
          recalculate_total(){
            console.log("got to recalc")
            this.grand_total = 0;
            this.products.forEach(element => {
                console.log(element.price+", "+ this.grand_total)
                this.grand_total += element.price;
            })
          },
          showCheckOutModal() {     
            this.form2.reset();
            this.form2.customer_barcode_id = '';
            this.$refs.customer_barcode_id.focus(); 
            this.customer = {};
            this.form2.grand_total = this.grand_total;
            this.form2.errors.set({});
            $("#checkOutModal").modal("show");
        },

        },
        mounted() {
            console.log('User Component mounted.')                           
            this.$refs.barcode_id.focus();     
        },
        created() {
        }
    }
</script>