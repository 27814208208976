<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-md-10">        
            <div class="card mt-3">
              <div class="card-header"><h2>Choose Membership Plan</h2></div>
              <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/icons8-medal-100.png"/>
                                <h3 class="text-dark">Jambo - All Member</h3>
                                <h5 class="text-dark">KES 100 p.m. / 1,000 p.a.</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="ticked"><small>Qualify for ward Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="cancelled"><small>Qualify for county Amb. Seat</small></li>
                                <li class="cancelled"><small>Qualify for regional Ambasadorial seat</small></li>
                                <li class="cancelled"><small>Attend quaterly board meeting</small></li>
                                <li class="cancelled"><small>Business networking</small></li>
                                <li class="cancelled"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="cancelled"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li class="cancelled"><small>Discount for Lubricants at Shell</small></li>
                                <li class="cancelled"><small>Restaurant discounts with Eatout</small></li>
                                <li class="cancelled"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="cancelled"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="cancelled"><small>One year LATAK Board Membership</small></li>
                                <li class="cancelled"><small>One year Dividends Payments</small></li>
                                <li class="cancelled"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/1">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/icons8-certificate-100.png"/>
                                <h3 class="text-dark">Bronze</h3>
                                <h5 class="text-dark">KES 2,400</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="cancelled"><small>Qualify for ward Amb. Position</small></li>
                                <li class="cancelled"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="cancelled"><small>Qualify for county Amb. Seat</small></li>
                                <li class="cancelled"><small>Qualify for regional Ambasodorial seat</small></li>
                                <li class="cancelled"><small>Attend quaterly board meeting</small></li>
                                <li class="cancelled"><small>Business networking</small></li>
                                <li class="cancelled"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="ticked"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li class="ticked"><small>Discount for Lubricants at Shell</small></li>
                                <li class="ticked"><small>Restaurant discounts with Eatout</small></li>
                                <li class="ticked"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="ticked"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="ticked"><small>One year LATAK Board Membership</small></li>
                                <li class="ticked"><small>One year Dividends Payments</small></li>
                                <li class="ticked"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/2">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/icons8-bronze-medal-80.png"/>
                                <h3 class="text-dark">Silver</h3>
                                <h5 class="text-dark">KES 30,000</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="ticked"><small>Qualify for ward Amb. Position</small></li>
                                <li class="cancelled"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="cancelled"><small>Qualify for county Amb. Seat</small></li>
                                <li class="cancelled"><small>Qualify for regional Ambasodorial seat</small></li>
                                <li class="cancelled"><small>Attend quaterly board meeting</small></li>
                                <li class="cancelled"><small>Business networking</small></li>
                                <li class="cancelled"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="ticked"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li class="ticked"><small>Discount for Lubricants at Shell</small></li>
                                <li class="ticked"><small>Restaurant discounts with Eatout</small></li>
                                <li class="ticked"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="ticked"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="ticked"><small>One year LATAK Board Membership</small></li>
                                <li class="ticked"><small>One year Dividends Payments</small></li>
                                <li class="cancelled"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/3">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/icons8-membership-card-100.png"/>
                                <h3 class="text-dark">Palladium</h3>
                                <h5 class="text-dark">KES 50,000</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="ticked"><small>Qualify for ward Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="cancelled"><small>Qualify for county Amb. Seat</small></li>
                                <li class="cancelled"><small>Qualify for regional Ambasodorial seat</small></li>
                                <li class="cancelled"><small>Attend quaterly board meeting</small></li>
                                <li class="cancelled"><small>Business networking</small></li>
                                <li class="cancelled"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="ticked"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li><small>Free 1-month Voice Minutes <span class="text-black">600 minutes</span></small></li>
                                <li><small>Free 1-month Data Bundles  <span class="text-black">15gb</span></small></li>
                                <li class="ticked"><small>Discount for Lubricants at Shell</small></li>
                                <li class="ticked"><small>Restaurant discounts with Eatout</small></li>
                                <li class="ticked"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="ticked"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="ticked"><small>One year LATAK Board Membership</small></li>
                                <li class="cancelled"><small>One year Dividends Payments</small></li>
                                <li class="cancelled"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/4">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/medal.png"/>
                                <h3 class="text-dark">Gold</h3>
                                <h5 class="text-dark">KES 100,000</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="ticked"><small>Qualify for ward Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for county Amb. Seat</small></li>
                                <li class="cancelled"><small>Qualify for regional Ambasodorial seat</small></li>
                                <li class="cancelled"><small>Attend quaterly board meeting</small></li>
                                <li class="cancelled"><small>Business networking</small></li>
                                <li class="cancelled"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="ticked"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li><small>Free 1-month Voice Minutes <span class="text-black">600 minutes</span></small></li>
                                <li><small>Free 1-month Data Bundles  <span class="text-black">15gb</span></small></li>
                                <li class="ticked"><small>Discount for Lubricants at Shell</small></li>
                                <li class="ticked"><small>Restaurant discounts with Eatout</small></li>
                                <li class="ticked"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="ticked"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="ticked"><small>One year LATAK Board Membership</small></li>
                                <li class="ticked"><small>One year Dividends Payments</small></li>
                                <li class="cancelled"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/5">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/premium.png"/>
                                <h3 class="text-dark">Platinum</h3>
                                <h5 class="text-dark">KES 30,000</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="ticked"><small>Qualify for ward Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for county Amb. Seat</small></li>
                                <li class="ticked"><small>Qualify for regional Ambasodorial seat</small></li>
                                <li class="ticked"><small>Attend quaterly board meeting</small></li>
                                <li class="ticked"><small>Business networking</small></li>
                                <li class="cancelled"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="ticked"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li><small>Free 1-month Voice Minutes <span class="text-black">200 minutes</span></small></li>
                                <li><small>Free 1-month Data Bundles  <span class="text-black">5gb</span></small></li>
                                <li class="ticked"><small>Discount for Lubricants at Shell</small></li>
                                <li class="ticked"><small>Restaurant discounts with Eatout</small></li>
                                <li class="ticked"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="ticked"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="ticked"><small>One year LATAK Board Membership</small></li>
                                <li class="ticked"><small>One year Dividends Payments</small></li>
                                <li class="ticked"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/6">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card text-white mb-3 bg-warnings">
                        <div class="card-body">
                            <div class="text-center">
                                <img  src="/images/icons/diamond.png"/>
                                <h3 class="text-dark">Rhodium</h3>
                                <h5 class="text-dark">KES 300,000</h5>
                            </div>
                            <ul class="text-white">
                                <li class="ticked"><small>Representation by latak & referral points.</small></li>
                                <li class="ticked"><small>Qualify for ward Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for sub-county Amb. Position</small></li>
                                <li class="ticked"><small>Qualify for county Amb. Seat</small></li>
                                <li class="ticked"><small>Qualify for regional Ambasodorial seat</small></li>
                                <li class="ticked"><small>Attend quaterly board meeting</small></li>
                                <li class="ticked"><small>Business networking</small></li>
                                <li class="ticked"><small>Access to project fund</small></li>
                                <li class="ticked"><small>Life Membership Certificate</small></li>
                                <li class="ticked"><small>Affordable Legal Services</small></li>
                                <li class="ticked"><small>Free Tenancy services</small></li>
                                <li class="ticked"><small>MEMBERSHIP WELCOME GIFT</small></li>
                                <li><small>Free 1-month Voice Minutes <span class="text-black">200 minutes</span></small></li>
                                <li><small>Free 1-month Data Bundles  <span class="text-black">5gb</span></small></li>
                                <li class="ticked"><small>Discount for Lubricants at Shell</small></li>
                                <li class="ticked"><small>Restaurant discounts with Eatout</small></li>
                                <li class="ticked"><small>Travel and Events with Ticketsasa</small></li>
                                <li class="ticked"><small>Free 1-month Unlimited SMS</small></li>
                                <li class="ticked"><small>One year LATAK Board Membership</small></li>
                                <li class="cancelled"><small>One year Dividends Payments</small></li>
                                <li class="cancelled"><small>One year Sports Club membership</small></li>
                            </ul>
                            <div class="row justify-content-center">
                                <router-link class="btn btn-outline-dark text-white" to="/auth/register/pay_for_plan/7">CLICK HERE</router-link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
            }
        },
    }
</script>

<style>
.bg-warnings{
    background: #d4af37;
}
ul{
    padding-left: 0px;
}
ul .ticked{
    list-style-type: '\2713';
    padding-left: 10px;
}
ul .cancelled{
    list-style-type: '\2717';
    padding-left: 10px;
}
.card .card-body .text-center img{
    max-height:50px;
}
.card-body{
    min-height:266px;
}
</style>
