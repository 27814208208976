<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-md-10">        
            <div class="card mt-3">
              <div class="card-header"><h2>Download the respective form</h2></div>
              <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card text-white mb-3 bg-warnings">
                            <div class="card-body">
                                <h5 class="card-title">Membership application form</h5>
                                <iframe src="images/forms/Latak-Membership-form.pdf" width="100%" height="500px"></iframe>
                                <div class="row justify-content-center">
                                    <button class="btn btn-warning text-white" @click="download('Latak-Membership-form.pdf','images/forms/Latak-Membership-form.pdf')">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card text-white mb-3 bg-warnings">
                            <div class="card-body">
                                <h5 class="card-title">Landlord/Tenant info form</h5>
                                <iframe src="images/forms/Landlord-Tenant-Info-Form.pdf" width="100%" height="500px"></iframe>
                                <div class="row justify-content-center">
                                    <button class="btn btn-warning text-white" @click="download('Landlord-Tenant-Info-Form.pdf','images/forms/Landlord-Tenant-Info-Form.pdf')">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card text-white mb-3 bg-warnings">
                            <div class="card-body">
                                <h5 class="card-title">Pretenancy application form</h5>
                                <iframe src="images/forms/LATAK-PRE-TENANCY-REPORT.pdf" width="100%" height="500px"></iframe>
                                <div class="row justify-content-center">
                                    <button class="btn btn-warning text-white" @click="download('LATAK-PRE-TENANCY-REPORT.docx','images/forms/LATAK-PRE-TENANCY-REPORT.pdf')">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card text-white mb-3 bg-warnings">
                            <div class="card-body">
                                <h5 class="card-title">Property reservation form</h5>
                                <iframe src="images/forms/LATAK-Property-Reservation-Form.pdf" width="100%" height="500px"></iframe>
                                <div class="row justify-content-center">
                                    <button class="btn btn-warning text-white" @click="download('LATAK-Property-Reservation-Form.pdf','images/forms/LATAK-Property-Reservation-Form.pdf')">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
            }
        },
        methods: {
            download(filename, url) {
              axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');
   
                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', filename+'.pdf');
                     document.body.appendChild(fileLink);
   
                     fileLink.click();
                });
          }
        }
    }
</script>

<style>
.bg-warnings{
    background: #d4af37;
}
ul{
    padding-left: 0px;
}
ul .ticked{
    list-style-type: '\2713';
    padding-left: 10px;
}
ul .cancelled{
    list-style-type: '\2717';
    padding-left: 10px;
}
.card .card-body .text-center img{
    max-height:50px;
}
.card-body{
    min-height:266px;
}
</style>