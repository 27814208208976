
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header p-2">
                            <h5>File a Complaint</h5>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                            <form class="form">                                
                                <div class="form-group row">
                                    <label for="message" class="col-sm-6 control-label">Message</label>
                                    <div class="col-sm-12">
                                        <textarea v-model="form.message" class="form-control" id="message" placeholder="Message" :class="{ 'is-invalid': form.errors.has('message') }"/>
                                        <has-error :form="form" field="message"></has-error>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="file" class="col-sm-6 control-label">File Upload</label>
                                    <div class="col-sm-12">
                                        <input type="file" ref="file_upload" @change="handleFileUpload" :class="{ 'is-invalid': form.errors.has('file') }"  class="form-control" id="file"  name="file">
                                        <has-error :form="form" field="file"></has-error>
                                    </div>
                                </div>  

                                <div class="form-group row">
                                    <label class="col-sm-6 control-label">How do we contact you?:</label>
                                    <div class="col-sm-12">
                                        <input type="radio" id="email" value="Email" key="email" v-model="form.contact_method">
                                        <label for="email" class="pr-4">Email</label>
                                        <input type="radio" id="whatsapp" value="Whatsapp" key="landlord" v-model="form.contact_method">
                                        <label for="whatsapp" class="pr-4">Whatsapp</label>
                                        <input type="radio" id="phone_call" value="Phone Call" key="agent" v-model="form.contact_method">
                                        <label for="phone_call" class="pr-4">Phone Call</label>                                    
                                        <has-error :form="form" field="formFunctions"></has-error>
                                    </div>                                            
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-offset-2 col-sm-12">
                                        <button @click.prevent="fileComplaint('address')" type="submit" class="btn btn-success">File Complaint</button>
                                    </div>
                                </div>
                            </form>
                        </div><!-- /.card-body -->

                    </div><!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import Form from 'vform'
    export default {
        data(){
            return {
                form: new Form({
                    contact_method: "",message: "",file: ""
                }),          
            }
        },
        mounted() {
 
        },
        methods:{
            handleFileUpload(e){
                const file = this.$refs.file_upload.files[0];
                this.form.file = file;        
            },
            async fileComplaint () {
                this.$Progress.start();            
                
                // update the user
                var formData = new FormData();
                var fields = ['contact_method', 'message'];
                fields.forEach(field => {
                    formData.append(field,this.form[field]);
                })
                if(this.form.file)
                    formData.append('file', this.form.file, this.form.file.name);
                var data;
                axios({
                    method: 'POST',
                    url: '/api/complaint',
                    data: formData,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
                })
                .then(response => {
                    this.$Progress.finish();
                    this.form.reset();
                    this.$refs.file_upload.value = null;
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                .catch(error => {
                    this.form.errors.set(error.response.data.errors);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },

        },
    }
</script>
