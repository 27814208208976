
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header p-2">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a @click.stop="change_tab('basic_details')" :class="active_tab == 'basic_details' ? 'nav-link active show' : 'nav-link'" href="#basic_details" data-toggle="tab">Basic Details</a></li>
                                <li class="nav-item"><a @click.stop="change_tab('address')" :class="active_tab == 'address' ? 'nav-link active show' : 'nav-link'" href="#address" data-toggle="tab">Residential Address</a></li>
                                <li class="nav-item"><a @click.stop="change_tab('property')" :class="active_tab == 'property' ? 'nav-link active show' : 'nav-link'" href="#property" data-toggle="tab">Residential Property Details</a></li>
                                <li class="nav-item"><a @click.stop="change_tab('change-password')" :class="active_tab == 'change-password' ? 'nav-link active show' : 'nav-link'" href="#change-password" data-toggle="tab">Change Password</a></li>
                            </ul>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                            <div class="tab-content">
                                <!-- Basic Details Tab -->
                                <div :class="active_tab == 'basic_details' ? 'tab-pane active show' : 'tab-pane'" id="basic_details">
                                    <form class="form-horizontal">
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">You are a:</label>
                                            <div class="col-sm-10">
                                                <input type="checkbox" id="tenant" value="tenant" key="tenant" v-model="formFunctions">
                                                <label for="tenant" class="pr-4">Tenant</label>
                                                <input type="checkbox" id="landlord" value="landlord" key="landlord" v-model="formFunctions">
                                                <label for="landlord" class="pr-4">Landlord</label>
                                                <input type="checkbox" id="agent" value="agent" key="agent" v-model="formFunctions">
                                                <label for="agent" class="pr-4">Agent</label>
                                                <input type="checkbox" id="corporate" value="corporate" key="corporate" v-model="formFunctions">
                                                <label for="corporate" class="pr-4">Corporate</label>                                            
                                                <has-error :form="form" field="formFunctions"></has-error>
                                            </div>                                            
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-2 control-label">Title</label>
                                            <div class="col-sm-10">
                                                <select name="title" v-model="form.title" id="title" class="form-control" :class="{ 'is-invalid': form.errors.has('title') }">
                                                    <option value="">Select Title</option>
                                                    <option value="Mr">Mr</option>
                                                    <option  value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option  value="Dr">Dr</option>
                                                    <option value="Eng">Eng</option>
                                                </select>
                                                <has-error :form="form" field="title"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="surname" class="col-sm-2 control-label">Surname</label>

                                            <div class="col-sm-10">
                                            <input type="text" v-model="form.surname" class="form-control" id="surname" placeholder="Enter Surname" :class="{ 'is-invalid': form.errors.has('surname') }" :readonly="form.surname.length > 0">
                                            <has-error :form="form" field="surname"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="other_names" class="col-sm-2 control-label">Other Names</label>

                                            <div class="col-sm-10">
                                            <input type="text" v-model="form.other_names" class="form-control" id="other_names" placeholder="Other names" :class="{ 'is-invalid': form.errors.has('other_names') }"  :readonly="form.other_names.length > 0">
                                            <has-error :form="form" field="other_names"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="id_no" class="col-sm-2 control-label">ID Number</label>

                                            <div class="col-sm-10">
                                                <input type="text" v-model="form.id_no" class="form-control" id="id_no" placeholder="ID No." :class="{ 'is-invalid': form.errors.has('id_no') }" readonly>
                                                <has-error :form="form" field="id_no"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">Date of Birth</label>
                                            <div class="col-sm-10">
                                                <date-picker v-model="form.date_of_birth" name="date_of_birth" class="form-control" :class="{ 'is-invalid': form.errors.has('date_of_birth') }" :config="options" placeholder="Select your Date of Birth"/>
                                                <has-error :form="form" field="date_of_birth"></has-error>
                                            </div>                                            
                                        </div>
                                        <div class="form-group">
                                            <div class="col-sm-offset-2 col-sm-12">
                                                <button @click.prevent="updateProfile('address')" type="submit" class="btn btn-success">Update Profile</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- Address Tab -->
                                <div :class="active_tab == 'address' ? 'tab-pane active show' : 'tab-pane'" id="address">
                                    <form class="form-horizontal">
                                        <div class="form-group row">
                                            <label for="physical_address" class="col-sm-2 control-label">Physical Address</label>
                                            <div class="col-sm-10">
                                                <textarea v-model="form.physical_address" class="form-control" id="physical_address" placeholder="Physical address" :class="{ 'is-invalid': form.errors.has('physical_address') }"/>
                                                <has-error :form="form" field="physical_address"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">County</label>
                                            <div class="col-sm-10">
                                                <select @change="load_constituencies" name="county" v-model="form.county" id="county" class="form-control" :class="{ 'is-invalid': form.errors.has('county') }">
                                                    <option value="">Select County</option>
                                                    <option v-for="(cname,index) in counties" :value="index" :key="index">{{cname}}</option>
                                                </select>
                                            </div>
                                            <has-error :form="form" field="county"></has-error>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">Constituency</label>
                                            <div class="col-sm-10">
                                                <select @change="load_wards" name="constituency" v-model="form.constituency" id="constituency" class="form-control" :class="{ 'is-invalid': form.errors.has('constituency') }">
                                                    <option value="">{{ (form.county == "" || form.county === undefined) ? "Select County First" : "Select Constituency" }}</option>
                                                    <option v-for="(cname,index)  in constituencies" :value="index" :key="index">{{cname}}</option>
                                                </select>
                                                <has-error :form="form" field="constituency"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">Ward</label>
                                            <div class="col-sm-10">
                                                <select @change="load_areas" name="ward" v-model="form.ward" id="ward" class="form-control" :class="{ 'is-invalid': form.errors.has('ward') }">
                                                    <option value="">{{ (form.constituency == "" || form.constituency === undefined) ? "Select Constituency First" : "Select Ward" }}</option>
                                                    <option v-for="(cname,index) in wards" :value="index" :key="index">{{cname}}</option>
                                                </select>
                                                <has-error :form="form" field="ward"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">Area</label>
                                            <div class="col-sm-10">
                                                <select name="area" v-model="form.area" id="area" class="form-control" :class="{ 'is-invalid': form.errors.has('area') }">
                                                    <option value="">{{ (form.ward == "" || form.ward === undefined) ? "Select Ward First" : "Select Area" }}</option>
                                                    <option v-for="(cname,index)  in areas" :value="index" :key="index">{{cname}}</option>
                                                </select>
                                                <has-error :form="form" field="area"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 control-label">You have stayed here since:</label>
                                            <div class="col-sm-10">
                                                <date-picker v-model="form.date_of_moving_in" name="date_of_moving_in" placeholder="Select Moving in Date"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('date_of_moving_in') }" :config="options"/>
                                                <has-error :form="form" field="date_of_moving_in"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="email" class="col-sm-2 control-label">Email Address</label>
                                            <div class="col-sm-10">
                                            <input type="email" v-model="form.email" class="form-control" id="email" placeholder="email" :class="{ 'is-invalid': form.errors.has('email') }">
                                            <has-error :form="form" field="email"></has-error>
                                            </div>
                                        </div> 
                                        <div class="form-group">
                                            <div class="col-sm-offset-2 col-sm-12">
                                                <button @click.prevent="updateProfile('property')" type="submit" class="btn btn-success">Update Profile</button>
                                            </div>
                                        </div>                                       
                                    </form>
                                </div>

                                <!-- Property Tab -->
                                <div :class="active_tab == 'property' ? 'tab-pane active show' : 'tab-pane'" id="property">
                                    <form class="form-horizontal">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Are you the owner of where you stay?</label>
                                            <div class="col-sm-12">
                                                <select name="ownership" v-model="form.ownership" id="ownership" class="form-control" :class="{ 'is-invalid': form.errors.has('ownership') }">
                                                    <option value="">Select yes / no</option>
                                                    <option v-for="ownership in yes_nos" :value="ownership" :key="ownership">{{ownership}}</option>
                                                </select>
                                                <has-error :form="form" field="ownership"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Do you have a mortgage on the house you stay in?</label>
                                            <div class="col-sm-12">
                                                <select name="mortgage" v-model="form.mortgage" id="mortgage" class="form-control" :class="{ 'is-invalid': form.errors.has('mortgage') }">
                                                    <option value="">Select yes / no</option>
                                                    <option v-for="mortgage in yes_nos" :value="mortgage" :key="mortgage">{{mortgage}}</option>
                                                </select>
                                                <has-error :form="form" field="mortgage"></has-error>
                                            </div>
                                        </div>

                                        <div v-if="(form.ownership == 'Yes' && form.mortgage == 'Yes') || form.ownership == 'No'" class="form-group">
                                            <label for="amount" class="col-sm-6 control-label">{{ form.ownership == 'Yes' && form.mortgage == 'Yes' ? 'Mortgage Amount' : form.ownership == 'No' ? 'Rent Amount' : ''  }}</label>
                                            <div class="col-sm-12">
                                                <input type="number" v-model="form.amount" class="form-control" id="amount" placeholder="Amount" :class="{ 'is-invalid': form.errors.has('amount') }">
                                                <has-error :form="form" field="amount"></has-error>
                                            </div>
                                        </div>

                                        <div v-if="!user_profile.referred_by" class="form-group">
                                            <label for="referred_by" class="col-sm-6 control-label">Who referred you?(member no.)</label>
                                            <div class="col-sm-12">
                                                <input type="text" v-model="form.referred_by" class="form-control" id="referred_by" placeholder="Member No. of person who referred you" :class="{ 'is-invalid': form.errors.has('referred_by') }">
                                                <has-error :form="form" field="referred_by"></has-error>
                                            </div>                                            
                                        </div>                                        

                                        <div class="form-group">
                                            <label for="inputPhoto" class="col-sm-2 control-label">Profile Photo</label>

                                            <div class="col-sm-12">
                                                <input type="file" ref="file_upload" @change="handleFileUpload" accept="image/*" :class="{ 'is-invalid': form.errors.has('file') }"  class="form-control" id="inputPhoto"  name="file_upload">
                                                <has-error :form="form" field="file_upload"></has-error>
                                            </div>
                                        </div>  

                                        <p class="ml-3">Your link to invite members is <a :href="'https://member.latak.or.ke/auth/register?ref='+user_profile.member_no">member.latak.or.ke/auth/register?ref={{ user_profile.member_no }}</a> <button @click="copy" class="btn btn-primary">Copy <i class="fa fa-copy nav-icon white"></i></button></p>
                                        <input type="hidden" id="testing-code" :value="'https://member.latak.or.ke/auth/register?ref='+user_profile.member_no">
                                        <div class="form-group">
                                            <div class="col-sm-offset-2 col-sm-12">
                                                <button @click.prevent="updateProfile('basic_details')" type="submit" class="btn btn-success">Update Profile</button>
                                            </div>
                                        </div>                                       
                                    </form>
                                </div>

                                <!-- Change Password Tab -->
                                <div  :class="active_tab == 'change-password' ? 'tab-pane active show' : 'tab-pane'" id="change-password">
                                    <form class="form-horizontal">
                                    <div class="form-group">
                                        <label for="current_password" class="col-sm-6 control-label">Current Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.current_password"
                                                class="form-control"
                                                id="current_password"
                                                placeholder="Current Password"
                                                :class="{ 'is-invalid': form.errors.has('current_password') }"
                                            >
                                            <has-error :form="form" field="current_password"></has-error>
                                        
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="new_password" class="col-sm-6 control-label">New Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.new_password"
                                                class="form-control"
                                                id="new_password"
                                                placeholder="New Password"
                                                :class="{ 'is-invalid': form.errors.has('new_password') }"
                                            >
                                            <has-error :form="form" field="new_password"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="confirm_password" class="col-sm-6 control-label">Confirm Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.confirm_password"
                                                class="form-control"
                                                id="confirm_password"
                                                placeholder="Confirm Password"
                                                :class="{ 'is-invalid': form.errors.has('confirm_password') }"
                                            >
                                            <has-error :form="form" field="confirm_password"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-12">
                                        <button @click.prevent="updatePassword" type="submit" class="btn btn-success">Update Password</button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
            </div>
            <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import Form from 'vform'
    // Import datepicker component
    import datePicker from 'vue-bootstrap-datetimepicker';
    // Import date picker css
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    export default {
        data(){
            return {
                form: new Form({
                    title: "",surname: "",other_names: "",id_no: "",date_of_birth: "",physical_address: "",county: "",constituency: "",ward: "",area: "",date_of_moving_in: "",email: "",ownership: "",mortgage: "",amount: "",referred_by: "",
                }),
                active_tab : 'basic_details',
                user_profile : {},
                yes_nos:['Yes','No'],
                counties:[],
                constituencies:[],
                wards:[],
                areas:[],
                formFunctions:[],
                options: {
                    viewMode: 'years',
                    format: 'YYYY-MM-DD',
                    useCurrent: false,
                    maxDate: new Date().toJSON().slice(0,10).replace(/-/g,'/'),
                    widgetPositioning: {
                        horizontal: 'left',
                        vertical: 'top'
                    },
                },
            }
        },
        mounted() {
            console.log('Component mounted.') 
        },
        components: {
            datePicker,
        },
        methods:{
            handleFileUpload(e){
                const file = this.$refs.file_upload.files[0];
                this.form.file = file;        
            },
            async copy() {
                let testingCodeToCopy = document.querySelector('#testing-code')
                testingCodeToCopy.setAttribute('type', 'text') 
                testingCodeToCopy.select()

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    Toast.fire({
                        icon: 'success',
                        title: 'Link copied Successfully.'
                    });
                } catch (err) {
                    alert('Oops, unable to copy');
                }

                /* unselect the range */
                testingCodeToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
            },
            async updateProfile (next_tab) {
                this.$Progress.start();
                if(this.form.password == ''){
                    this.form.password = undefined;
                }                
                
                // update the user
                var formData = new FormData();
                var fields = ['functions','title','surname','other_names','id_no','physical_address','county','constituency','ward','area','email','ownership','mortgage','amount','referred_by'];
                fields.forEach(field => {
                    field != 'functions' ? formData.append(field,this.form[field]) : formData.append(field,this.formFunctions);
                })
                formData.append('date_of_birth', !this.form.date_of_birth ? "" : this.form.date_of_birth);
                formData.append('date_of_moving_in', !this.form.date_of_moving_in ? "" : this.form.date_of_moving_in);
                if(this.form.file)
                    formData.append('file', this.form.file, this.form.file.name);
                var data;
                axios({
                    method: 'POST',
                    url: '/api/profile',
                    data: formData,
                    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
                })
                .then(response => {
                    this.$Progress.finish();
                    this.user_profile = response.data.data
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    this.active_tab = next_tab
                })
                .catch(error => {
                    this.form.errors.set(error.response.data.errors);
                    this.$Progress.fail();
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },
            change_tab(tab){
                if(!this.user_profile.password_set && tab != 'change-password'){
                    this.active_tab = 'change-password'
                    Toast.fire({
                        icon: 'error',
                        title: 'Kindly change your password first.'
                    });
                }
                else{
                    this.active_tab = tab
                }                
            },

            updatePassword(){
                this.$Progress.start();
                this.form.post('api/change-password')
                .then((response)=>{
                    //  Fire.$emit('AfterCreate');
                    this.$Progress.finish();
                    this.form.current_password = '';
                    this.form.new_password = '';
                    this.form.confirm_password = '';
                    this.user_profile = response.data.data;
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                .catch(() => {                    
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },
            load_counties(){
                this.$Progress.start()
                axios
                .get("/api/category/counties")
                .then(({ data }) => (this.counties = data.data))
                this.form.county = this.form.constituency = this.form.ward = this.form.area = ""
                this.constituencies = this.wards = this.areas = []
                this.$Progress.finish();
            },
            load_constituencies(){
                this.$Progress.start()
                axios
                .get("/api/category/constituencies/"+this.form.county)
                .then(({ data }) => (this.constituencies = data.data))
                this.form.constituency = this.form.ward = this.form.area = ""
                this.wards = this.areas = []
                this.$Progress.finish();
            },
            load_wards(){
                this.$Progress.start()
                axios
                .get("/api/category/wards/"+this.form.constituency)
                .then(({ data }) => (this.wards = data.data))
                this.form.ward = this.form.area = ""
                this.areas = []
                this.$Progress.finish();
            },
            load_areas(){
                this.$Progress.start()
                axios
                .get("/api/category/areas/"+this.form.ward)
                .then(({ data }) => (this.areas = data.data))
                this.form.area = 0
                this.$Progress.finish();
            },
            async load_filled_lists(county,constituency,ward){
                this.$Progress.start()
                if(county){
                    axios
                    .get("/api/category/constituencies/"+county)
                    .then(({ data }) => (this.constituencies = data.data))
                    if(constituency){
                        await axios
                        .get("/api/category/wards/"+constituency)
                        .then(({ data }) => (this.wards = data.data))
                        if(ward){
                            await axios
                            .get("/api/category/areas/"+ward)
                            .then(({ data }) => (this.areas = data.data))
                            this.$Progress.finish();
                        }
                    }
                }
            },
        },
        created() {

            this.$Progress.start();
            axios.get("api/profile")
            .then(({ data }) => {
                this.form.fill(data.data)
                this.formFunctions = data.data.functions ? data.data.functions.split(",") : []
                this.user_profile = data.data
                var fields = ['functions','title','surname','other_names','id_no','date_of_birth','physical_address','county','constituency','ward','area','date_of_moving_in','email','ownership','mortgage','amount','referred_by'];
                fields.forEach(field => {
                    this.form[field] = !this.form[field] ? "" : this.form[field];
                })
                if(!data.data.password_set){
                    this.active_tab = 'change-password'
                    Toast.fire({
                        icon: 'error',
                        title: 'Kindly change your password.'
                    });
                }
                
                this.load_filled_lists(this.form.county,this.form.constituency,this.form.ward);
            });
            this.load_counties()
            
            this.$Progress.finish();
        }
    }
</script>
