<template>
    <div class="container mt-4">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a :class="active_tab == 'basic-info-tab' ? 'nav-link active' : 'nav-link'" @click="change_tab('basic-info-tab')" data-toggle="tab" href="#basic-info-tab">Basic Information</a>
        </li>
        <li class="nav-item">
          <a :class="active_tab == 'specifications-tab' ? 'nav-link active' : 'nav-link'" @click="change_tab('specifications-tab')" data-toggle="tab" href="#specifications-tab">Specifications</a>
        </li>
        <li class="nav-item">
          <a :class="active_tab == 'leasing-terms' ? 'nav-link active' : 'nav-link'" @click="change_tab('leasing-terms')" data-toggle="tab" href="#leasing-terms">Leasing Terms</a>
        </li>
        <li class="nav-item">
          <a :class="active_tab == 'costs' ? 'nav-link active' : 'nav-link'" @click="change_tab('costs')" data-toggle="tab" href="#costs">Costs</a>
        </li>
        <li class="nav-item">
          <a :class="active_tab == 'features' ? 'nav-link active' : 'nav-link'" @click="change_tab('features')" data-toggle="tab" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a :class="active_tab == 'description' ? 'nav-link active' : 'nav-link'" @click="change_tab('description')" data-toggle="tab" href="#description">Description</a>
        </li>
      </ul>
      <form>
        <div class="tab-content">
          <!-- Basic Information Tab -->
          <div :class="active_tab == 'basic-info-tab' ? 'tab-pane fade show active pt-3' : 'tab-pane fade show pt-3'" id="basic-info-tab">
            <div class="form-group row">
              <label for="title" class="col-sm-2 col-form-label">Title</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="title" v-model="form.title">
              </div>
            </div>
            <div class="form-group row">
              <label for="address" class="col-sm-2 col-form-label">Address</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="address" v-model="form.address">
              </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 control-label">County</label>
                <div class="col-sm-10">
                    <select @change="load_constituencies" name="county" v-model="form.county" id="county" class="form-control" :class="{ 'is-invalid': form.errors.has('county') }">
                        <option value="">Select County</option>
                        <option v-for="(cname,index) in counties" :value="index" :key="index">{{cname}}</option>
                    </select>
                </div>
                <has-error :form="form" field="county"></has-error>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 control-label">Constituency</label>
                <div class="col-sm-10">
                    <select @change="load_wards" name="constituency" v-model="form.constituency" id="constituency" class="form-control" :class="{ 'is-invalid': form.errors.has('constituency') }">
                        <option value="">{{ (form.county == "" || form.county === undefined) ? "Select County First" : "Select Constituency" }}</option>
                        <option v-for="(cname,index)  in constituencies" :value="index" :key="index">{{cname}}</option>
                    </select>
                    <has-error :form="form" field="constituency"></has-error>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 control-label">Ward</label>
                <div class="col-sm-10">
                    <select @change="load_areas" name="ward" v-model="form.ward" id="ward" class="form-control" :class="{ 'is-invalid': form.errors.has('ward') }">
                        <option value="">{{ (form.constituency == "" || form.constituency === undefined) ? "Select Constituency First" : "Select Ward" }}</option>
                        <option v-for="(cname,index) in wards" :value="index" :key="index">{{cname}}</option>
                    </select>
                    <has-error :form="form" field="ward"></has-error>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 control-label">Area</label>
                <div class="col-sm-10">
                    <select name="area" v-model="form.area" id="area" class="form-control" :class="{ 'is-invalid': form.errors.has('area') }">
                        <option value="">{{ (form.ward == "" || form.ward === undefined) ? "Select Ward First" : "Select Area" }}</option>
                        <option v-for="(cname,index)  in areas" :value="index" :key="index">{{cname}}</option>
                    </select>
                    <has-error :form="form" field="area"></has-error>
                </div>
            </div>
            <div class="form-group row">
              <label for="thumbnail-image" class="col-sm-2 col-form-label">Thumbnail Image</label>
              <div class="col-sm-10">
                <input type="file" ref="thumbnail_image" @change="handleThumbnailUpload" accept="image/*" class="form-control-file" id="thumbnail_image"  name="thumbnail_image">
              </div>
            </div>
            <div class="form-group row">
              <label for="gallery_image" class="col-sm-2 col-form-label">Gallery Image</label>
              <div class="col-sm-10">
                <input type="file" ref="gallery_image" @change="handleGalleryUpload" accept="image/*" class="form-control-file" id="gallery_image"  name="gallery_image" multiple>
              </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="float-sm-left" style="display:none;">
                        <button type="button" class="btn btn-default btn-lg">
                            <span class="far fa-arrow-alt-circle-left"></span>
                        </button>
                    </div>
                    <div class="float-sm-right">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('specifications-tab')">
                            <span class="far fa-arrow-alt-circle-right"></span>
                        </button>
                    </div>
                </div>
            </div>
          </div>
          <!-- Specifications Tab -->
          <div :class="active_tab == 'specifications-tab' ? 'tab-pane fade show active pt-3' : 'tab-pane fade show pt-3'" id="specifications-tab">
            <div class="row">
                <div class="col-md-6 form-group">
                <label for="type-of-housing">Type of Housing</label>
                <select class="form-control" id="type-of-housing" v-model="form.type_of_housing">
                    <option value="Brick house">Brick house</option>
                    <option value="New House">New House</option>
                    <option value="Panel house">Panel house</option>
                </select>
                </div>
                <div class="col-md-6 form-group">
                <label for="property-type">Property Type</label>
                <select class="form-control" id="property-type" v-model="form.property_type">
                    <option value="Apartments">Apartments</option>
                    <option value="Duplex">Duplex</option>
                    <option value="Garages">Garages</option>
                    <option value="Houses">Houses</option>
                    <option value="Townhouses">Townhouses</option>
                </select>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 form-group">
                <label for="purpose">Purpose</label>
                <select class="form-control" id="purpose" v-model="form.purpose">
                    <option value="Rent">Rent</option>
                    <option value="Sale">Sale</option>
                </select>
                </div>
                <div class="col-md-6 form-group">
                <label for="price">Price</label>
                <input type="text" class="form-control" id="price" v-model="form.price">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="property-size">Property Size</label>
                    <input type="text" class="form-control" id="property-size" v-model="form.property_size">
                </div>
                <div class="col-md-6 form-group">
                    <label for="bedrooms">Bedrooms</label>
                    <input type="text" class="form-control" id="bedrooms" v-model="form.bedrooms">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 form-group">
                <label for="bathrooms">Bathrooms</label>
                <input type="text" class="form-control" id="bathrooms" v-model="form.bathrooms">
                </div>
                <div class="col-md-6 form-group">
                <label for="heating">Heating</label>
                <input type="text" class="form-control" id="heating" v-model="form.heating">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 form-group">
                <label for="year-built">Year Built</label>
                <input type="text" class="form-control" id="year-built" v-model="form.year_built">
                </div>
                <div class="col-md-6 form-group">
                <label for="accomodation">Accommodation</label>
                <select class="form-control" id="accomodation" v-model="form.accomodation">
                    <option value="Without Repair">Without Repair</option>
                    <option value="With designer renovation">With designer renovation</option>
                    <option value="furnished">Furnished</option>
                    <option value="with-repair">With Repair</option>
                    <option value="rough-finish">Rough Finish</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="parking" class="col-sm-3 col-form-label">Parking</label>
                    <select id="parking" class="form-control" v-model="form.parking">
                        <option value="">--Please Select--</option>
                        <option value="free-zone">Free Zone</option>
                        <option value="paid-zone">Paid Zone</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label for="ceiling-height" class="col-sm-3 col-form-label">Ceiling Height</label>
                    <input type="number" id="ceiling-height" class="form-control" v-model="form.ceiling_height">
                </div>  
            </div>

            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="garages" class="col-sm-3 col-form-label">Garages</label>
                    <select id="garages" class="form-control" v-model="form.garages">
                        <option value="">--Please Select--</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label for="garages-size" class="col-sm-3 col-form-label">Garages Size</label>
                    <input type="number" id="garages-size" class="form-control" v-model="form.garages_size">
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 form-group">
                    <label for="area-size" class="col-sm-3 col-form-label">Area Size</label>
                    <input type="number" id="area-size" class="form-control" v-model="form.area_size">
                </div>

                <div class="col-md-6 form-group">
                    <label for="additional-space" class="col-sm-6 col-form-label">Additional Space</label>
                    <select id="garages" class="form-control" v-model="form.additional_space">
                        <option value="">--Please Select--</option>
                        <option value="storage">Storage</option>
                        <option value="playground">Playground</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="float-sm-left">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('basic-info-tab')">
                            <span class="far fa-arrow-alt-circle-left"></span>
                        </button>
                    </div>

                    <div class="float-sm-right">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('leasing-terms')">
                            <span class="far fa-arrow-alt-circle-right"></span>
                        </button>
                    </div>
                </div>
            </div>
          </div>
          <div :class="active_tab == 'leasing-terms' ? 'tab-pane fade show active pt-3' : 'tab-pane fade show pt-3'" id="leasing-terms" role="tabpanel">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="deposit-amount">Deposit Amount</label>
                        <input type="text" class="form-control" id="deposit-amount" v-model="form.deposit_amount">
                    </div>
                    <div class="form-group">
                        <label for="allowed-pets">Allowed Pets</label>
                        <select class="form-control" id="allowed-pets" v-model="form.allowed_pets">
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="payment-period">Payment Period</label>
                        <select class="form-control" id="payment-period" v-model="form.payment_period">
                            <option value="">Select</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="habitable">Habitable</label>
                        <select class="form-control" id="habitable" v-model="form.habitable">
                            <option value="">Select</option>
                            <option value="Instantly">Instantly</option>
                            <option value="Notice">Notice</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="minimum-lease-term">Minimum Lease Term</label>
                        <input type="text" class="form-control" id="minimum-lease-term" v-model="form.minimum_lease_term">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="float-sm-left">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('settings-tab')">
                            <span class="far fa-arrow-alt-circle-left"></span>
                        </button>
                    </div>

                    <div class="float-sm-right">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('costs')">
                            <span class="far fa-arrow-alt-circle-right"></span>
                        </button>
                    </div>
                </div>
            </div>
          </div>
          <div :class="active_tab == 'costs' ? 'tab-pane fade show active pt-3' : 'tab-pane fade show pt-3'" id="costs" role="tabpanel" aria-labelledby="costs-tab">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="utilities">Utilities</label>
                        <input type="text" class="form-control" id="utilities" v-model="form.utilities">
                    </div>
                    <div class="form-group">
                        <label for="cable_tv">Cable TV</label>
                        <select class="form-control" id="cable_tv" v-model="form.cable_tv">
                            <option value="">Please select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">                    
                    <div class="form-group">
                        <label for="electricity">Electricity</label>
                        <select class="form-control" id="electricity" v-model="form.electricity">
                            <option value="">Please select</option>
                            <option value="post_paid">Post Paid</option>
                            <option value="prepaid">Prepaid</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="float-sm-left">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('leasing-terms')">
                            <span class="far fa-arrow-alt-circle-left"></span>
                        </button>
                    </div>

                    <div class="float-sm-right">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('features')">
                            <span class="far fa-arrow-alt-circle-right"></span>
                        </button>
                    </div>
                </div>
            </div>
          </div>
          <div :class="active_tab == 'features' ? 'tab-pane fade show active pt-3' : 'tab-pane fade show pt-3'" id="features" role="tabpanel" aria-labelledby="features-tab">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="ac_and_heating" v-model="form.ac_and_heating">
                        <label class="form-check-label" for="ac_and_heating">AC and Heating</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="balcony" v-model="form.balcony">
                        <label class="form-check-label" for="balcony">Balcony</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="club_house" v-model="form.club_house">
                        <label class="form-check-label" for="club_house">Club House</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="dish_washer" v-model="form.dish_washer">
                        <label class="form-check-label" for="dish_washer">Dish Washer</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="elevator" v-model="form.elevator">
                        <label class="form-check-label" for="elevator">Elevator</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="fitness_center" v-model="form.fitness_center">
                        <label class="form-check-label" for="fitness_center">Fitness Center</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="granite_countertops" v-model="form.granite_countertops">
                        <label class="form-check-label" for="granite_countertops">Granite Countertops</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="laundry_facilities" v-model="form.laundry_facilities">
                        <label class="form-check-label" for="laundry_facilities">Laundry Facilities</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="modern_kitchen" v-model="form.modern_kitchen">
                        <label class="form-check-label" for="modern_kitchen">Modern Kitchen</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="pet_friendly" v-model="form.pet_friendly">
                        <label class="form-check-label" for="pet_friendly">Pet Friendly</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="pool_and_spa" v-model="form.pool_and_spa">
                        <label class="form-check-label" for="pool_and_spa">Pool and Spa</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="float-sm-left">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('costs')">
                            <span class="far fa-arrow-alt-circle-left"></span>
                        </button>
                    </div>

                    <div class="float-sm-right">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('description')">
                            <span class="far fa-arrow-alt-circle-right"></span>
                        </button>
                    </div>
                </div>
            </div>
          </div>
          <div :class="active_tab == 'description' ? 'tab-pane fade show active pt-3' : 'tab-pane fade show pt-3'" id="description" role="tabpanel" aria-labelledby="description-tab">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="description">Full Description</label>
                    <vue-editor v-model="form.description"></vue-editor>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="float-sm-left">
                        <button type="button" class="btn btn-default btn-lg" @click="change_tab('features')">
                            <span class="far fa-arrow-alt-circle-left"></span>
                        </button>
                    </div>

                    <div class="float-sm-right">
                        <button @click.prevent="submitForm" type="submit" class="btn btn-success">Add Property</button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
</template>

<script>
    import axios from 'axios';
    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    // Import datepicker component
    import datePicker from 'vue-bootstrap-datetimepicker';
    // Import date picker css
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    export default {
        data(){
            return {
                tabs: ['Basic Information', 'Specifications', 'Leasing Terms', 'Costs', 'Features', 'Description'],
                active_tab: 'basic-info-tab',
                form: new Form({
                    title: '',
                    address: '',
                    county:'',
                    constituency:'',
                    ward:'',
                    area:'',
                    thumbnail: null,
                    gallery: [],
                    type_of_housing: '',
                    property_type: '',
                    purpose: '',
                    price: '',
                    property_size: '',
                    bedrooms: '',
                    bathrooms: '',
                    heating: '',
                    year_built: '',
                    accomodation: '',
                    parking: '',
                    ceiling_height: '',
                    garages: '',
                    garages_size: '',
                    area_size: '',
                    additional_space: '',
                    deposit_amount: '',
                    allowed_pets: '',
                    payment_period: '',
                    habitable: '',
                    minimum_lease_term: '',
                    utilities: '',
                    cable_tv: '',
                    electricity: '',
                    ac_and_heating: false,
                    balcony: false,
                    club_house: false,
                    dish_washer: false,
                    elevator: false,
                    fitness_center: false,
                    granite_countertops: false,
                    laundry_facilities: false,
                    modern_kitchen: false,
                    pet_friendly: false,
                    pool_and_spa: false,
                    description: '',
                }),
                active_tab : 'basic_details',
                user_profile : {},
                yes_nos:['Yes','No'],
                counties:[],
                constituencies:[],
                wards:[],
                areas:[],
                formFunctions:[],
                options: {
                    viewMode: 'years',
                    format: 'YYYY-MM-DD',
                    useCurrent: false,
                    maxDate: new Date().toJSON().slice(0,10).replace(/-/g,'/'),
                    widgetPositioning: {
                        horizontal: 'left',
                        vertical: 'top'
                    },
                },
            }
        },
        mounted() {
            console.log('Component mounted.') 
        },
        components: {
            datePicker,VueEditor
        },
        methods:{
            handleThumbnailUpload(e){
                const file = this.$refs.thumbnail_image.files[0];
                this.form.thumbnail = file; 
            },
            handleGalleryUpload(e){
                const files = this.$refs.gallery_image.files;
                for(var i=0;i<this.$refs.gallery_image.files.length;i++){
                    this.form.gallery[i] = this.$refs.gallery_image.files[i];
                }
                console.log(this.form.gallery);      
            },
            
            load_counties(){
                this.$Progress.start()
                axios
                .get("/api/category/counties")
                .then(({ data }) => (this.counties = data.data))
                this.form.county = this.form.constituency = this.form.ward = this.form.area = ""
                this.constituencies = this.wards = this.areas = []
                this.$Progress.finish();
            },
            load_constituencies(){
                this.$Progress.start()
                axios
                .get("/api/category/constituencies/"+this.form.county)
                .then(({ data }) => (this.constituencies = data.data))
                this.form.constituency = this.form.ward = this.form.area = ""
                this.wards = this.areas = []
                this.$Progress.finish();
            },
            load_wards(){
                this.$Progress.start()
                axios
                .get("/api/category/wards/"+this.form.constituency)
                .then(({ data }) => (this.wards = data.data))
                this.form.ward = this.form.area = ""
                this.areas = []
                this.$Progress.finish();
            },
            load_areas(){
                this.$Progress.start()
                axios
                .get("/api/category/areas/"+this.form.ward)
                .then(({ data }) => (this.areas = data.data))
                this.form.area = 0
                this.$Progress.finish();
            },
            change_tab(tab){
                this.active_tab = tab              
            },
            async load_filled_lists(county,constituency,ward){
                this.$Progress.start()
                if(county){
                    axios
                    .get("/api/category/constituencies/"+county)
                    .then(({ data }) => (this.constituencies = data.data))
                    if(constituency){
                        await axios
                        .get("/api/category/wards/"+constituency)
                        .then(({ data }) => (this.wards = data.data))
                        if(ward){
                            await axios
                            .get("/api/category/areas/"+ward)
                            .then(({ data }) => (this.areas = data.data))
                            this.$Progress.finish();
                        }
                    }
                }
            },
            submitForm() {
                const formData = new FormData();

                // Add Basic Information fields to formData
                formData.append('title', this.form.title);
                formData.append('address', this.form.address);
                formData.append('county', this.form.county);
                formData.append('constituency', this.form.constituency);
                formData.append('ward', this.form.ward);
                formData.append('area', this.form.area);
                formData.append('thumbnail', this.form.thumbnail);
                if(this.form.gallery){
                    this.form.gallery.forEach((image) => {
                        formData.append('gallery[]', image);
                    });
                }

                // Add Specifications fields to formData
                formData.append('type_of_housing', this.form.type_of_housing);
                formData.append('property_type', this.form.property_type);
                formData.append('purpose', this.form.purpose);
                formData.append('price', this.form.price);
                formData.append('property_size', this.form.property_size);
                formData.append('bedrooms', this.form.bedrooms);
                formData.append('bathrooms', this.form.bathrooms);
                formData.append('heating', this.form.heating);
                formData.append('year_built', this.form.year_built);
                formData.append('accomodation', this.form.accomodation);
                formData.append('parking', this.form.parking);
                formData.append('ceiling_height', this.form.ceiling_height);
                formData.append('garages', this.form.garages);
                formData.append('garages_size', this.form.garages_size);
                formData.append('area_size', this.form.area_size);
                formData.append('additional_space', this.form.additional_space);

                // Add Leasing Terms fields to formData
                formData.append('deposit_amount', this.form.deposit_amount);
                formData.append('allowed_pets', this.form.allowed_pets);
                formData.append('payment_period', this.form.payment_period);
                formData.append('habitable', this.form.habitable);
                formData.append('minimum_lease_term', this.form.minimum_lease_term);

                // Add Costs fields to formData
                formData.append('utilities', this.form.utilities);
                formData.append('cable_tv', this.form.cable_tv);
                formData.append('electricity', this.form.electricity);

                // Add Features fields to formData
                formData.append('ac_and_heating', this.form.ac_and_heating);
                formData.append('balcony', this.form.balcony);
                formData.append('club_house', this.form.club_house);
                formData.append('dish_washer', this.form.dish_washer);
                formData.append('elevator', this.form.elevator);
                formData.append('fitness_center', this.form.fitness_center);
                formData.append('granite_countertops', this.form.granite_countertops);
                formData.append('laundry_facilities', this.form.laundry_facilities);
                formData.append('modern_kitchen', this.form.modern_kitchen);
                formData.append('pet_friendly', this.form.pet_friendly);
                formData.append('pool_and_spa', this.form.pool_and_spa);
                // Add Description field to formData
                formData.append('description', this.form.description);

                // Submit formData using Axios
                axios.post('/api/listing', formData)
                    .then(response => {
                            Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                    })
                        .catch((error) => {
                            // Error message or handling
                        });
            },
        },
        created() {
            this.load_counties();
            this.change_tab('basic-info-tab');
        },
    }
</script>
