<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">
                                Payments List
                            </h3>

                            <div class="card-tools">                                
                                <form class="form-inline btn btn-sm">
                                    <select id="pagination" v-model="form3.items_per_page" @change="getResults">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                    </select>
                                </form>
                                <!--<button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>-->
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table table-hover table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th style="width: 25%">Paid in By</th>
                                        <th style="width: 10%">Member No.</th>
                                        <th style="width: 10%">Amount</th>
                                        <th style="width: 10%">Plan</th>
                                        <th style="width: 10%">Applies From</th>
                                        <th style="width: 10%">Applies To</th>
                                        <th style="width: 10%">Method</th>
                                        <th style="width: 10%">Created At</th>
                                        <th style="width: 5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input v-model="form2.user_name" type="text" class="form-control form-control-sm" placeholder="Name Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.member_no" type="text" class="form-control form-control-sm" placeholder="Member No Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.amount" type="text" class="form-control form-control-sm" placeholder="Amount Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.plan" type="text" class="form-control form-control-sm" placeholder="Plan Search"/>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>   
                                        <td>
                                            <select v-model="form2.payment_method" class="form-control form-control-sm">
                                                <option value="">Method</option>
                                                <option value="M-Pesa">M-Pesa</option>
                                                <option value="Paypal">Paypal</option>
                                            </select>
                                        </td>
                                        <td>

                                        </td>  
                                        <td>
                                                <a
                                                    href="#"
                                                    @click="getResults"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="payment in payments.data"
                                        :key="payment.id"
                                    >
                                        <td>{{ payment.user_name }}</td>
                                        <td>{{ payment.user.member_no }}</td>
                                        <td>{{ payment.amount }}</td>
                                        <td>{{ payment.plan }}</td>
                                        <td>{{ payment.applies_from }}</td>
                                        <td>{{ payment.applies_to }}</td>
                                        <td>{{ payment.payment_method }}</td>
                                        <td>{{payment.created_at | moment("YYYY-MM-DD HH:MM:ss")}}</td>
                                        <td v-if="$route.path == '/payments'">
                                            <a
                                                href="#"
                                                @click="deletePayment(
                                                        payment.id
                                                    )"
                                            >
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            /
                                            <a
                                                href="#"
                                                @click="
                                                    deletePayment(
                                                        payment.id
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="payments"
                                @pagination-change-page="getResults"
                            ></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>
            <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Add New Payment</h5>
                    <h5 class="modal-title" v-show="editmode">Update Payment's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updatePayment() : createPayment()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="email" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        
                        <div class="form-group">
                            <label>Phone</label>
                            <input v-model="form.phone" type="text" name="phone"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select User Role</option>
                                <option v-if="$gate.isSuperAdmin()" value="super_admin">Super Admin</option>
                                <option v-if="$gate.isAdmin()" value="admin">Admin</option>
                                <option value="subscriber">Subscriber</option>
                                <option value="deactivated">Deactivated User</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
// Import datepicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
//bar code modal
import VueBarcode from 'vue-barcode';
export default {
    data() {
        return {
            editmode: false,
            payments: {},
            form2: new Form({
                user_name: "",
                member_no: "",
                amount: "",
                plan: "",
                payment_method: "",
            }),
            form3: new Form({
                items_per_page: "10",
            }),  
            form: new Form({
                items_per_page: "10",
            }),      
            users:[],
        };
    },
    components: {
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            var search_string = "";
            if(this.form2.user_name != ""){
                search_string = search_string + "user_name=" + this.form2.user_name + "&";
            }
            if(this.form2.member_no != ""){
                search_string = search_string + "member_no=" + this.form2.member_no + "&";
            }
            if(this.form2.amount != ""){
                search_string = search_string + "amount=" + this.form2.amount + "&";
            }
            if(this.form2.plan != ""){
                search_string = search_string + "plan=" + this.form2.plan + "&";
            }            
            if(this.form2.payment_method != ""){
                search_string = search_string + "payment_method=" + this.form2.payment_method + "&";
            }            
            search_string = Buffer.from(search_string).toString('base64');
            axios
                .get("api/payment?page=" + page + "&items_per_page=" + this.form3.items_per_page +"&search_string=" + search_string)
                .then(({ data }) => (this.payments = data.data));

            this.$Progress.finish();
        },
        cancelSearch(){
            this.form2.user_name = "";
            this.form2.member_no = "";
            this.form2.amount = "";
            this.form2.plan = "";
            this.form2.payment_method = "";
            this.getResults();
        },

        newModal() {
            this.editmode = false;
            this.promotemode = false;
            this.form.reset();
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        deletePayment(id) {
            Swal.fire({
                title: "Not Possible",
                text: "You can't be able to delete financial transactions!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Okay!",
            })
        },
        deletePaymentOld(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .delete("api/member/" + id)
                        .then(() => {
                            Swal.fire(
                                "Deleted!",
                                "Your file has been deleted.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.loadCustomers();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        loadPayments() {
            this.$Progress.start();

            if (this.$gate.isSuperAdmin()) {
                axios
                    .get("api/payment")
                    .then(({ data }) => (this.payments = data.data));
            }

            this.$Progress.finish();
        },
        loadUsers() {
            axios
                .get("/api/user/list")
                .then(({ data }) => (this.users = data.data));
        },
    },

    mounted() {
        console.log("Member Component mounted.");
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.$Progress.finish();
    },
};
</script>
