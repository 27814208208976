<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                My Benefits List - {{ user.plan }}
                            </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-3">
                            <ol>
                                <li v-for="benefit in benefits" :key="benefit.id">{{ benefit.name }}</li>
                            </ol>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            benefits: [],     
            user: {},
        };
    },
    methods: {
        async loadBenefits(){
            this.$Progress.start();
            await axios.get("/api/profile/user_benefits").then(({ data }) => (this.benefits = data.data));
            this.$Progress.finish();
        },
    },

    mounted() {
    },
    created() {
        this.$Progress.start();
        this.loadBenefits();
        this.$Progress.finish();
        this.user = window.user;
    },
};
</script>
