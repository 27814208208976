<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-house-user"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Number of Properties</span>
                            <span class="info-box-number">
                            {{ dashboard.number_of_properties }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                
                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-12 col-md-6">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">No. of Referred Members</span>
                            <span class="info-box-number">{{dashboard.number_of_referrals}}</span>
                        </div>
                    <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    export default {
        data(){
            return {
                dashboard: {},
            }
        },
        created() {
            this.$Progress.start()
                axios
                .get("/api/dashboard")
                .then(({ data }) => (this.dashboard = data.data))
                this.$Progress.finish();
        },
        mounted() {

            console.log('Component mounted.')
        }
    }
</script>
