<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-md-8">        
            <div class="card mt-3">
              <div class="card-header">Login</div>
              <div class="card-body">
                <form @submit.prevent="login()">
                  <div class="form-group row">
                    <label for="user_name" class="col-md-4 col-form-label text-md-right">User name</label>
                    <div class="col-md-6">
                      <input v-model="form.user_name" type="text" name="user_name"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('user_name') }" required autocomplete="user_name" autofocus>
                      <has-error :form="form" field="user_name"></has-error>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>
                    <div class="col-md-6">
                      <input v-model="form.password" type="password" name="password"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" required>
                      <has-error :form="form" field="password"></has-error>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-6 offset-md-4">
                      <div class="form-check">
                        <input v-model="form.remember" type="checkbox" name="remember" id="remember"
                          class="form-check-input" :class="{ 'is-invalid': form.errors.has('remember') }">
                          <label class="form-check-label" for="remember">
                              Remember Me
                          </label>
                      </div>                      
                    </div>
                  </div>

                  <div class="form-group row mb-0">
                    <div class="col-md-8 offset-md-4">
                      <button type="submit" class="btn btn-primary">
                        Login
                      </button>
                      <router-link class="btn btn-link" to="/auth/forgot_password">Forgot your Password</router-link>
                      <router-link class="btn btn-link" to="/auth/register/choose_plan">Register</router-link>
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                form: new Form({
                    user_name : '',
                    password : '',
                }),
            }
        },
        methods: {

            login() {

                  this.$Progress.start();
                  this.form.email = this.form.user_name;
                  this.form.post('/api/login');

                  this.$Progress.finish();
            },
            updateFld(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/first_level_division/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadFld();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(fld){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(fld);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteFld(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/first_level_division/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadFld();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
          loadFld(){
            this.$Progress.start();

            if(this.$gate.isSuperAdmin()){
              axios.get("api/first_level_division").then(({ data }) => (this.first_level_divisions = data.data));
            }

            this.$Progress.finish();
          },
          
          createFld(){

              this.form.post('api/first_level_division')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadFld();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            //this.organization_type = window.user.organization_type;
            //this.loadFld();
            this.$Progress.finish();
        }
    }
</script>
