<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">
                                USSD Call Back Requests List
                            </h3>

                            <div class="card-tools">                                
                                <form class="form-inline btn btn-sm">
                                    <select id="pagination" v-model="form3.items_per_page" @change="getResults">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                    </select>
                                </form>
                                <!--<button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>-->
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="
table table table-hover table-bordered table-striped
 table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 13%">Requested By</th>
                                        <th style="width: 10%">Phone</th>
                                        <th style="width: 10%">Service</th>
                                        <th style="width: 10%">Status</th>
                                        <th style="width: 15%">Requested on</th>
                                        <th style="width: 13%">Called Back By</th>
                                        <th style="width: 15%">Called Back At</th>
                                        <th style="width: 5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input v-model="form2.user_name" type="text" class="form-control form-control-sm" placeholder="Name Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.phone" type="text" class="form-control form-control-sm" placeholder="Phone No Search"/>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <select v-model="form2.status" class="form-control form-control-sm">
                                                <option value="">Status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Complete">Complete</option>
                                            </select>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>   
                                        <td>

                                        </td>
                                        <td>
                                                <a
                                                    href="#"
                                                    @click="getResults(1)"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="ussd_call_back_request in ussd_call_back_requests.data"
                                        :key="ussd_call_back_request.id"
                                    >
                                        <td>{{ ussd_call_back_request.user_name }}</td>
                                        <td>{{ ussd_call_back_request.user.phone }}</td>
                                        <td>{{ ussd_call_back_request.service_chosen }}</td>
                                        <td>{{ ussd_call_back_request.status }}</td>
                                        <td>{{ussd_call_back_request.created_at | moment("YYYY-MM-DD HH:MM:ss")}}</td>
                                        <td>{{ ussd_call_back_request.called_back_by_user_name }}</td>
                                        <td>{{ussd_call_back_request.called_back_at | moment("YYYY-MM-DD HH:MM:ss")}}</td>
                                        <td v-if="$route.path == '/ussd_call_back_requests' && ussd_call_back_request.status == 'Pending'">
                                            <a
                                                href="#"
                                                @click="mark_complete(
                                                        ussd_call_back_request.id
                                                    )"
                                            >
                                                <i class="fa fa-check blue"></i>
                                            </a>
                                        </td>
                                        <td v-if="$route.path == '/ussd_call_back_requests' && ussd_call_back_request.status == 'Complete'">
                                            <a
                                                href="#"
                                                @click="mark_pending(
                                                        ussd_call_back_request.id
                                                    )"
                                            >
                                                <i class="fa fa-adjust blue"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="ussd_call_back_requests"
                                @pagination-change-page="getResults"
                            ></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>
            <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Add New Payment</h5>
                    <h5 class="modal-title" v-show="editmode">Update Payment's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updatePayment() : createPayment()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="email" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        
                        <div class="form-group">
                            <label>Phone</label>
                            <input v-model="form.phone" type="text" name="phone"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option value="">Select User Role</option>
                                <option v-if="$gate.isSuperAdmin()" value="super_admin">Super Admin</option>
                                <option v-if="$gate.isAdmin()" value="admin">Admin</option>
                                <option value="subscriber">Subscriber</option>
                                <option value="deactivated">Deactivated User</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
// Import datepicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
//bar code modal
import VueBarcode from 'vue-barcode';
export default {
    data() {
        return {
            editmode: false,
            ussd_call_back_requests: {},
            form2: new Form({
                user_name: "",
                phone: "",
                status: "",
            }),
            form3: new Form({
                items_per_page: "10",
            }),  
            form: new Form({
                items_per_page: "10",
            }),      
            users:[],
        };
    },
    components: {
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            var search_string = "";
            if(this.form2.user_name != ""){
                search_string = search_string + "user_name=" + this.form2.user_name + "&";
            }
            if(this.form2.phone != ""){
                search_string = search_string + "phone=" + this.form2.phone + "&";
            }
   
            if(this.form2.status != ""){
                search_string = search_string + "status=" + this.form2.status + "&";
            }            
            search_string = Buffer.from(search_string).toString('base64');
            axios
                .get("api/ussd_call_back_request?page=" + page + "&items_per_page=" + this.form3.items_per_page +"&search_string=" + search_string)
                .then(({ data }) => (this.ussd_call_back_requests = data.data));

            this.$Progress.finish();
        },
        cancelSearch(){
            this.form2.user_name = "";
            this.form2.phone = "";
            this.form2.status = "";
            this.getResults();
        },

        mark_complete(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "Kindly confirm you have called back the user!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, I have called!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .post("api/ussd_call_back_requests/mark_complete/" + id)
                        .then(() => {
                            Swal.fire(
                                "Completed!",
                                "Call has been marked as complete.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.getResults();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        mark_pending(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "Kindly confirm that the user's issue has not been addressed!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, I have confirmed!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .post("api/ussd_call_back_requests/mark_pending/" + id)
                        .then(() => {
                            Swal.fire(
                                "Completed!",
                                "Call has been marked as unaddressed.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.getResults();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        loadUssdCallBackRequests() {
            this.$Progress.start();

            if (this.$gate.isSuperAdmin()) {
                axios
                    .get("api/ussd_call_back_requests")
                    .then(({ data }) => (this.ussd_call_back_requests = data.data));
            }

            this.$Progress.finish();
        },
        loadUsers() {
            axios
                .get("/api/user/list")
                .then(({ data }) => (this.users = data.data));
        },
    },

    mounted() {
        console.log("Member Component mounted.");
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.$Progress.finish();
    },
};
</script>
