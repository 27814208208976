<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 v-if="meal" class="card-title">Serving {{meal ? meal.name : ""}}</h3>
                <h3 v-else class="card-title text-danger">No meal for this time!</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <form @submit.prevent=""  class="form-horizontal mt-2 ml-4 mr-4">
                  <input type="hidden" name="meal_id" v-model="form.meal_id"/>
                  <input type="hidden" name="price" v-model="form.price"/>
                  <div class="form-group">
                      <input v-model="form.barcode_id" type="text" name="barcode_id" ref="barcode_id" placeholder="Scan Barcode"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('barcode_id') }" v-on:keyup.enter="search_customer" autofocus>
                      <has-error :form="form" field="barcode_id"></has-error>
                  </div>
                </form>
                <div class="container" v-if="customer">
                    <div class="row mb-4">
                        <div class="col-sm-6" style="text-align:center">
                            <img  style="width:200px; height:auto;border-radius: 50%;" :src="customer.photo_url"> 
                            <h3>{{customer.name}}</h3>
                            <p>{{customer.f_n_l}}</p>                             
                        </div>
                        <div class="col-sm-6">
                            <h2 :class="customer.text_class">{{customer.authorization}}</h2>
                        </div>
                        
                    </div>
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>
    </div>
  </section>
</template>

<script>
import VueMoment from 'vue-moment';
    export default {
        data () {
            return {
                editmode: false,
                meal : {},
                meals: {},
                customer: {},
                form: new Form({
                    barcode_id : '',
                    meal_id: '',
                    price: '',
                }),
            }
        },
        components: {
            moment: VueMoment
        },
        methods: {
          loadMeal(){
            this.$Progress.start();
            var date = new Date();
            axios.get("api/meal/current?time="+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()).then(({ data }) => (this.meal = data.data));
            this.$Progress.finish();
          },
          search_customer(){
            this.$Progress.start();
            this.form.meal_id = this.meal.id;            
            this.form.price = this.meal.price;  
            console.log(this.form.meal_id);
            this.form.post('api/customer/search_by_barcode')
            .then((response) => {
                // success
                this.customer = response.data.data     
                this.$Progress.finish();
                this.form.barcode_id = "";
                this.$refs.barcode_id.focus();
            })
            .catch(() => {
                this.$Progress.fail();
            });
          },

        },
        mounted() {
            console.log('User Component mounted.')                           
            this.$refs.barcode_id.focus();     
        },
        created() {

            this.$Progress.start();
            this.loadMeal();
            this.$Progress.finish();
        }
    }
</script>