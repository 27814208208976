<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                My Property List
                            </h3>

                            <div class="card-tools">                                
                                <form class="form-inline btn btn-sm">
                                    <select id="pagination" v-model="form3.items_per_page" @change="getResults">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                    </select>
                                </form>
                                <!--<button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>-->
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <!--<table class="
table table table-hover table-bordered table-striped
">
                                <thead>
                                    <tr>
                                        <th style="width: 25%">Paid in By</th>
                                        <th style="width: 10%">Member No.</th>
                                        <th style="width: 10%">Amount</th>
                                        <th style="width: 10%">Plan</th>
                                        <th style="width: 10%">Applies From</th>
                                        <th style="width: 10%">Applies To</th>
                                        <th style="width: 10%">Method</th>
                                        <th style="width: 10%">Created At</th>
                                        <th style="width: 5%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input v-model="form2.user_name" type="text" class="form-control form-control-sm" placeholder="Name Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.member_no" type="text" class="form-control form-control-sm" placeholder="Member No Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.amount" type="text" class="form-control form-control-sm" placeholder="Amount Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.plan" type="text" class="form-control form-control-sm" placeholder="Plan Search"/>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>   
                                        <td>
                                            <select v-model="form2.payment_method" class="form-control form-control-sm">
                                                <option value="">Method</option>
                                                <option value="M-Pesa">M-Pesa</option>
                                                <option value="Paypal">Paypal</option>
                                            </select>
                                        </td>
                                        <td>

                                        </td>  
                                        <td>
                                                <a
                                                    href="#"
                                                    @click="getResults"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="payment in payments.data"
                                        :key="payment.id"
                                    >
                                        <td>{{ payment.user_name }}</td>
                                        <td>{{ payment.user.member_no }}</td>
                                        <td>{{ payment.amount }}</td>
                                        <td>{{ payment.plan }}</td>
                                        <td>{{ payment.applies_from }}</td>
                                        <td>{{ payment.applies_to }}</td>
                                        <td>{{ payment.payment_method }}</td>
                                        <td>{{payment.created_at | moment("YYYY-MM-DD HH:MM:ss")}}</td>
                                        <td v-if="$route.path == '/payments'">
                                            <a
                                                href="#"
                                                @click="deletePayment(
                                                        payment.id
                                                    )"
                                            >
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            /
                                            <a
                                                href="#"
                                                @click="
                                                    deletePayment(
                                                        payment.id
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>-->
                            <div class="row">
                                <div class="col-sm-6 p-3" v-for="property in listings.data" :key="property.id">
                                    <div class="card p-2">
                                        <h5 class="card-title"><b>{{ property.title }}</b></h5>
                                        <img class="card-img-top" :src="property.thumbnail" alt="Card image cap">
                                        <div class="card-body">                                            
                                            <p class="card-text" v-html="property.description"></p>
                                            <router-link :to="'edit_property/'+property.id" class="card-link">All Details</router-link>
                                            <carousel>
                                                <slide v-for="(name,index) in JSON.parse(property.gallery_images)" :key="index">
                                                    <img :src="name">
                                                </slide>
                                            </carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="listings"
                                @pagination-change-page="getResults"
                            ></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import { Carousel, Slide } from 'vue-carousel';
export default {
    data() {
        return {
            listings: {},          
            form3: new Form({
                items_per_page: "10",
            }),      
        };
    },
    components: {
        Carousel, Slide
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            axios
                .get("api/listing?page=" + page + "&items_per_page=" + this.form3.items_per_page)
                .then(({ data }) => (this.listings = data.data));

            this.$Progress.finish();
        },
        cancelSearch(){
            this.form2.user_name = "";
            this.form2.member_no = "";
            this.form2.amount = "";
            this.form2.plan = "";
            this.form2.payment_method = "";
            this.getResults();
        },

        newModal() {
            this.editmode = false;
            this.promotemode = false;
            this.form.reset();
            this.form.errors.set({});
            $("#addNew").modal("show");
        },
        deletePayment(id) {
            Swal.fire({
                title: "Not Possible",
                text: "You can't be able to delete financial transactions!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Okay!",
            })
        },
        deletePaymentOld(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .delete("api/member/" + id)
                        .then(() => {
                            Swal.fire(
                                "Deleted!",
                                "Your file has been deleted.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.loadCustomers();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        loadPayments() {
            this.$Progress.start();

            if (this.$gate.isSuperAdmin()) {
                axios
                    .get("api/payment")
                    .then(({ data }) => (this.payments = data.data));
            }

            this.$Progress.finish();
        },
        loadUsers() {
            axios
                .get("/api/user/list")
                .then(({ data }) => (this.users = data.data));
        },
    },

    mounted() {
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.$Progress.finish();
    },
};
</script>
