<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-md-8">        
            <div class="card mt-3">
              <div class="card-header"><h2>Pay for {{ plan.name }}</h2></div>
              <div class="card-body">
                <form @submit.prevent="pay()">
                  <div class="form-group row">
                    <label for="frequency" class="col-md-4 col-form-label text-md-right">Frequency</label>
                    <div class="col-md-6">
                      <select @change="set_paypal_value()" name="frequency" v-model="form.frequency" id="frequency" class="form-control" :class="{ 'is-invalid': form.errors.has('frequency') }">
                        <option value="pm">Monthly</option>
                        <option value="pa">Annually</option>
                    </select>
                    <has-error :form="form" field="organization_type_id"></has-error>
                    </div>
                  </div>
                  <div class="text-center">
                    <h4 v-if="this.plan[this.form.frequency]">Pay KES <b>{{ plan[this.form.frequency].toLocaleString() }}</b> via: </h4>
                    <button @click="mpesa()" class="btn">
                      <img src="/images/mpesa.png" width="120" height="auto" />
                    </button>
                    <div  class="col-md-6 offset-md-4" v-if="paypal_active" id="paypal-button-container"></div>
                  </div>
                  <div v-if="show_phone" class="form-group row">
                    <label for="phone" class="col-md-4 col-form-label text-md-right">M-Pesa Phone No</label>
                    <div class="col-md-6">
                      <input v-model="form.phone" type="text" name="phone"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('user_name') }" required autocomplete="phone">
                      <has-error :form="form" field="phone"></has-error>
                    </div>
                  </div>
                  <div v-if="show_phone" class="form-group row mb-0">
                    <div class="col-md-8 offset-md-4">
                      <button type="submit" class="btn btn-success" :disabled="mpesa_request_pending">
                        {{ button_text }}
                      </button>
                    </div>
                  </div>
                  <div v-if="show_phone"  class="col-md-8 offset-md-4 mt-2" :class="alert_class" role="alert">
                    {{ alert_text }}
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>  
    export default {
        data () {
            return {
                editmode: false,
                plans : [
                  {"name":'Jambo','pm':100,"pa":1000},
                  {"name":'Bronze','pm':200,"pa":2400},
                  {"name":'Silver','pm':2500,"pa":30000},
                  {"name":'Palladium','pm':4200,"pa":50000},
                  {"name":'Gold','pm':8500,"pa":100000},
                  {"name":'Platinum','pm':12500,"pa":150000},
                  {"name":'Rhodium','pm':25000,"pa":300000},
                ],
                plan:{},
                show_phone:false,
                form: new Form({
                    frequency : 'pa',
                    amount : '0',
                    phone:'',
                    checkout_request_id: '',
                    confirmation_code: '',
                    type: 'normal'
                }),
                credentials: {
                  sandbox: 'AbBX54iBOEbtkIHni3p6B4nx6_iX_TGjdCYqxZBTxGH8dy63IUeFz8P14AQ7Lv-ScKxhqa_kfYYV12aD',
                  production: '<production client id>'
                },
                experience: {
                  input_fields: {
                    no_shipping: 1,
                  },
                },
                button_text: "Pay Via M-Pesa",
                alert_class: "alert alert-info",
                alert_text: "Click button to pay via m-pesa",
                mpesa_request_pending: false,
                waiting_billing: true,
                billing : [],
                payment: [],
                check_function_id:'',
                check_transaction_executions:0,
                paypal_value:0,
                paypal_active:false
            }
        },
        components: {
        },
        methods: {
            mpesa(){
              this.show_phone = !this.show_phone;
            },  
            async pay() {
              // Initiate M-pesa Online Transaction.
              this.form.amount = this.plan[this.form.frequency]
              this.alert_text = "Processing Payment...."
              this.$Progress.start();
              const response = await this.form.post('/register/initiate_mpesa_transaction');
              this.form.checkout_request_id = response.data.original.checkout_request_id;
              this.mpesa_request_pending = true;
              this.check_transaction_executions = 0;
              this.check_function_id = setInterval(this.check_transaction,3000);      
            },
            async check_transaction(){
              const response = await this.form.post('/api/register/check_mpesa_transaction');
              if(response.data.status == 'Complete'){
                this.payment = response.data;
                this.form.confirmation_code = this.payment.confirmation_code;
                this.mpesa_request_pending = false;
                this.alert_text = "Payment Successful. Check your phone for further information."
                this.alert_class = "alert alert-success"
                clearInterval(this.check_function_id);
              }
              if(response.data.status == 'Cancelled'){
                this.payment = response.data;
                this.form.confirmation_code = 'Cancelled';
                this.mpesa_request_pending = false;
                this.alert_text = "Payment Cancelled. Enter payment phone number and try again."
                this.alert_class = "alert alert-danger"
                clearInterval(this.check_function_id);
              }
              if(this.check_transaction_executions > 20){
                this.mpesa_request_pending = false;
                this.alert_text = "Payment Cancelled. Enter payment phone number and try again."
                this.alert_class = "alert alert-danger"
                clearInterval(this.check_function_id);
              }
              this.check_transaction_executions = this.check_transaction_executions + 1;
              this.button_text = "Pay Via M-Pesa"
              this.$Progress.finish();
            },
            paymentAuthorized: function (data) {
              console.log(data);
            },
            paymentCompleted: function (data) {
              console.log(data);
            },
            paymentCancelled: function (data) {
              console.log(data);
            },
            async set_paypal_value(){                
              let kes = this.plan[this.form.frequency]
              await axios.get('/api/mpesa_pay/to_usd?amount='+kes,{ headers: { apikey: 'SbTwHILQEvXFEp0qGbN1FED9CJ4r7wC0' } })
                .then((response) => {
                   this.paypal_value = response.data.paypal_value
                })
                .catch(() => {
                  this.paypal_value = kes / 100
                });
            },
        },
        mounted() {
          window.scrollTo(0,0);
          this.plan = this.plans[this.$route.params.plan_id - 1]
          this.set_paypal_value()
          if(this.paypal_active){
          paypal
            .Buttons({
              style: {
                shape: "rect",
                color: "white",
                layout: "vertical",
                label: "paypal",
                size: "small"
              },
          
              createOrder: async function(data, actions) {
                var date = new Date();
                var ref_id = date.getFullYear()+date.getMonth()+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+'-'+self.plan.name+'-'+self.form.frequency
                  return actions.order.create({
                    reference_id: ref_id,
                    shipping_preference: "NO_SHIPPING",
                    purchase_units: [
                      {                        
                        amount: {
                          value: self.paypal_value,
                          currency_code: "USD"
                        },
                      },
                    ],
                  });
              },
          // eslint-disable-next-line no-unused-vars
          onApprove: async function(data, actions) {
            /**
             * NOTE
             * - Save the subscription id in your Database
             * - This is important to ensure you can always
             * - Check on the status when user logs in or wants
             * - to make payment
             */

            // 2. Save payment subscription id
            //let subscrption_id = data.subscriptionID;

            // 4. Remove the selected package from the local storage

            localStorage.removeItem("@selectedpackage");

            // 5. Lets use swal to give us an alert once transcation is completed
            Swal.fire({
              icon: "success",
              title: "Congratulations",
              text: "Your payment has successfully been proccessed!",
              confirmButtonText: "Complete",
              showLoaderOnConfirm: true,
              preConfirm: () => {
                // redirect user to dashboard or login
                location.assign("http://localhost:8080/profile");
              },
              allowOutsideClick: false
            });
          }
        })
        .render("#paypal-button-container");}
        },
        created() {
          window.scrollTo(0,0);
          this.$Progress.start();
          var self = this
          
          this.$Progress.finish();
        }
    }
</script>
