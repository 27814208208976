export default [
    { path: '/auth', component: require('./components/auth/Login.vue').default },
    { path: '/auth/login', component: require('./components/auth/Login.vue').default },
    { path: '/auth/register', component: require('./components/auth/register/ChoosePlan.vue').default },
    { path: '/auth/register/choose_plan', component: require('./components/auth/register/ChoosePlan.vue').default },
    { path: '/auth/register/pay_for_plan/:plan_id', component: require('./components/auth/register/PayForPlan.vue').default },
    { path: '/edit_member/:member_id', component: require('./components/EditMember.vue').default },
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/add_property', component: require('./components/AddProperty.vue').default },
    { path: '/edit_property/:property_id', component: require('./components/EditProperty.vue').default },
    { path: '/complain', component: require('./components/Complain.vue').default },
    { path: '/complaints', component: require('./components/Complaints.vue').default },
    { path: '/my_complaints', component: require('./components/MyComplaints.vue').default },
    { path: '/my_property', component: require('./components/MyProperty.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/benefits_list', component: require('./components/BenefitsList.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/forms', component: require('./components/Forms.vue').default },
    { path: '/members', component: require('./components/Members.vue').default },
    { path: '/payments', component: require('./components/Payments.vue').default },
    { path: '/ussd_call_back_requests', component: require('./components/UssdCallBackRequests.vue').default },
    { path: '/incomplete_ussds', component: require('./components/IncompleteUssds.vue').default },
    { path: '/organizations', component: require('./components/Organizations.vue').default },
    { path: '/first_level_divisions', component: require('./components/First_level_divisions.vue').default },
    { path: '/second_level_divisions', component: require('./components/Second_level_divisions.vue').default },
    { path: '/seasons', component: require('./components/Seasons.vue').default },
    { path: '/customers', component: require('./components/Members.vue').default },
    { path: '/customers_barcodes', component: require('./components/Members.vue').default },
    { path: '/customers_promotions', component: require('./components/Members.vue').default },
    { path: '/customers_deactivations', component: require('./components/Members.vue').default },
    { path: '/meals_permissions', component: require('./components/Members.vue').default },
    { path: '/tuck_shop_balances', component: require('./components/Members.vue').default },
    { path: '/meals', component: require('./components/Meals.vue').default },
    { path: '/meals_between_dates', component: require('./components/MealsBetweenDates.vue').default },
    { path: '/serve_meals', component: require('./components/ServeMeals.vue').default },
    { path: '/sell_products', component: require('./components/SellProducts.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
