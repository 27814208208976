<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">
                                Complaints List
                            </h3>

                            <div class="card-tools">                                
                                <form class="form-inline btn btn-sm">
                                    <select id="pagination" v-model="form3.items_per_page" @change="getResults">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                    </select>
                                </form>
                                <!--<button type="button" class="btn btn-sm btn-primary" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>-->
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="
table table table-hover table-bordered table-striped
 table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 8%">Requested By</th>
                                        <th style="width: 13%">Message</th>
                                        <th style="width: 5%">File</th>
                                        <th style="width: 10%">Phone</th>
                                        <th style="width: 10%">Email</th>
                                        <th style="width: 10%">Contact Method</th>
                                        <th style="width: 10%">Status</th>
                                        <th style="width: 10%">Requested on</th>
                                        <th style="width: 11%">Addressed By</th>
                                        <th style="width: 10%">Addressed At</th>
                                        <th style="width: 4%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input v-model="form2.user_name" type="text" class="form-control form-control-sm" placeholder="Name Search"/>
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <input v-model="form2.phone" type="text" class="form-control form-control-sm" placeholder="Phone No Search"/>
                                        </td>
                                        <td>
                                            <input v-model="form2.email" type="text" class="form-control form-control-sm" placeholder="Email Search"/>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <select v-model="form2.status" class="form-control form-control-sm">
                                                <option value="">Status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Complete">Complete</option>
                                            </select>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>   
                                        <td>

                                        </td>
                                        <td>
                                                <a
                                                    href="#"
                                                    @click="getResults(1)"
                                                >
                                                    <i class="fa fa-search blue"></i>
                                                </a>
                                                /
                                                <a
                                                    href="#"
                                                    @click="cancelSearch"                                                    
                                                >
                                                    <i class="fa fa-search red"></i>
                                                </a>
                                            </td>
                                    </tr>
                                    <tr
                                        v-for="complaint in complaints.data"
                                        :key="complaint.id"
                                    >
                                        <td>{{ complaint.user_name }}</td>
                                        <td>{{ complaint.message }}</td>
                                        <td><a :href="complaint.file" target="_blank">Link</a></td>
                                        <td>{{ complaint.user.phone }}</td>
                                        <td>{{ complaint.email }}</td>
                                        <td>{{ complaint.contact_method }}</td>
                                        <td>{{ complaint.status }}</td>
                                        <td>{{complaint.created_at | moment("YYYY-MM-DD HH:MM:ss")}}</td>
                                        <td>{{ complaint.addressed_by_user_name }}</td>
                                        <td>{{complaint.addressed_at | moment("YYYY-MM-DD HH:MM:ss")}}</td>
                                        <td v-if="$route.path == '/complaints' && complaint.status == 'Pending'">
                                            <a
                                                href="#"
                                                @click="mark_complete(
                                                        complaint.id
                                                    )"
                                            >
                                                <i class="fa fa-check blue"></i>
                                            </a>
                                        </td>
                                        <td v-if="$route.path == '/complaints' && complaint.status == 'Complete'">
                                            <a
                                                href="#"
                                                @click="mark_pending(
                                                        complaint.id
                                                    )"
                                            >
                                                <i class="fa fa-adjust blue"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <pagination
                                :data="complaints"
                                @pagination-change-page="getResults"
                            ></pagination>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>

            <div v-if="!$gate.isAdmin()">
                <not-found></not-found>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
// Import datepicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {
    data() {
        return {
            complaints: {},
            form2: new Form({
                user_name: "",
                phone: "",
                email: "",
                status: "",
            }),
            form3: new Form({
                items_per_page: "10",
            }),  
            form: new Form({
                items_per_page: "10",
            }),      
            users:[],
        };
    },
    components: {
    },
    methods: {
        getResults(page = 1) {
            this.$Progress.start();
            var search_string = "";
            if(this.form2.user_name != ""){
                search_string = search_string + "user_name=" + this.form2.user_name + "&";
            }
            if(this.form2.phone != ""){
                search_string = search_string + "phone=" + this.form2.phone + "&";
            }

            if(this.form2.email != ""){
                search_string = search_string + "email=" + this.form2.email + "&";
            }
   
            if(this.form2.status != ""){
                search_string = search_string + "status=" + this.form2.status + "&";
            }            
            search_string = Buffer.from(search_string).toString('base64');
            axios
                .get("api/complaint?page=" + page + "&items_per_page=" + this.form3.items_per_page +"&search_string=" + search_string)
                .then(({ data }) => (this.complaints = data.data));

            this.$Progress.finish();
        },
        cancelSearch(){
            this.form2.user_name = "";
            this.form2.phone = "";
            this.form2.status = "";
            this.getResults();
        },

        mark_complete(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "Kindly confirm you have addressed the complaint!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, I have addressed!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .post("api/complaints/mark_complete/" + id)
                        .then(() => {
                            Swal.fire(
                                "Completed!",
                                "Complaint has been marked as addressed.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.getResults();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        mark_pending(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "Kindly confirm that the user's complaint has not been addressed!",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, I have confirmed!",
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.form
                        .post("api/complaints/mark_pending/" + id)
                        .then(() => {
                            Swal.fire(
                                "Completed!",
                                "Complaint has been marked as unaddressed.",
                                "success"
                            );
                            // Fire.$emit('AfterCreate');
                            this.getResults();
                        })
                        .catch((data) => {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                }
            });
        },
        loadComplaints() {
            this.$Progress.start();

            if (this.$gate.isSuperAdmin()) {
                axios
                    .get("api/complaints")
                    .then(({ data }) => (this.complaints = data.data));
            }

            this.$Progress.finish();
        },
        loadUsers() {
            axios
                .get("/api/user/list")
                .then(({ data }) => (this.users = data.data));
        },
    },

    mounted() {
        console.log("Member Component mounted.");
    },
    created() {
        this.$Progress.start();
        this.getResults();
        this.$Progress.finish();
    },
};
</script>
