<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" v-if="$gate.isAdmin()">
                        <div class="card-header">
                            <h3 class="card-title">
                                Select Dates To Generate Report For
                            </h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body form">
                            <form class="form"
                            @submit.prevent="downloadExcel()"
                        >
                            <div>
                                <div class="form-group">
                                    <label>Report Type</label>
                                    <select v-model="form.report_type" name="report_type"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('report_type') }"> 
                                        <option value="" disabled>Select Report Type</option>
                                        <option v-for="(name, index) in report_types" :key="index" :value="name" :selected="index == form.report_type">
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error :form="form" field="start_date"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Start Date</label>
                                    <date-picker v-model="form.start_date" name="start_date"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('start_date') }" :config="options"/>
                                    <has-error :form="form" field="start_date"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>End Date</label>
                                    <date-picker v-model="form.end_date" name="end_date"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('end_date') }" :config="options"/>
                                    <has-error :form="form" field="end_date"></has-error>
                                </div>
                                <div v-if="form.report_type == 'Meals Served By Grade'" class="form-group">
                                    <label>{{
                                        organization_type.first_level_division_name
                                    }}</label>
                                    <select
                                        name="first_level_division_id"
                                        v-model="form.first_level_division_id"
                                        id="first_level_division_id"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': form.errors.has(
                                                'first_level_division_id'
                                            ),
                                        }"
                                    >
                                        <option value="0" disabled>
                                            Select
                                            {{
                                                organization_type.first_level_division_name
                                            }}
                                        </option>
                                        <option
                                            v-for="(
                                                name, index
                                            ) in first_level_divisions"
                                            :key="index"
                                            :value="index"
                                            :selected="
                                                index ==
                                                form.first_level_division_id
                                            "
                                        >
                                            {{ name }}
                                        </option>
                                    </select>
                                    <has-error
                                        :form="form"
                                        field="first_level_division_id"
                                    ></has-error>
                                </div>
                                <div v-if="form.report_type == 'Meals Served To Individual'" class="form-group">
                                    <label>{{organization_type.customer_name}} ID</label>
                                    <input
                                            v-model="form.customer_id"
                                            type="text"
                                            name="customer_id"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    form.errors.has('customer_id'),
                                            }"
                                        />
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-primary">Download Excel</button>
		                        </div>
                            </div>
                        </form>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
// Import datepicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {
    data() {
        return {
            form: new Form({
                start_date: "",
                end_date: "",
                report_type: "",
                first_level_division_id: 0,
                customer_id:'',
            }),
            report_types : ['Meals Served Within Specific Dates','Meals Served By Grade', 'Meals Served To Individual'],
            first_level_divisions: [],
            organization_type: {},
            options: {
                format: 'YYYY-MM-DD',
                useCurrent: false,
                widgetPositioning: {
                    horizontal: 'left',
                    vertical: 'bottom'
                },
            },
        };
    },
    components: {
      datePicker,
    },
    methods: {
        downloadExcel() {
            this.$Progress.start();
            console.log("got to downloadExcel");
            axios({         
                url: '/api/meals_between_dates',
                method: 'POST',
                responseType: 'blob', // important
                data: {'start_date':this.form.start_date,'end_date':this.form.end_date,'report_type':this.form.report_type,'first_level_division_id':this.form.first_level_division_id,'customer_id':this.form.customer_id}
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'meals_between_dates.xlsx');
                document.body.appendChild(link);
                link.click();
                this.$Progress.finish();
            }).catch(error => {
                this.form.errors.set(error.response.data.errors);
                this.$Progress.fail();
                console.log(error.response);
            });
        },
        loadFlds() {
            axios
                .get("/api/first_level_divisions/list")
                .then(({ data }) => (this.first_level_divisions = data.data));
        },
    },

    mounted() {
        console.log("Meals Between Dates Component mounted.");
    },
    created() {
        console.log("Meals Between Dates Component created.");
        this.loadFlds();
        this.organization_type = window.user.organization_type;
    },
};
</script>
